import React from 'react';

import { Grid, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { RoundButton } from '../../components';
import Header from '../../components/header/header';
import InfiniteScrollContainer from '../../components/infinite-scroll-container/InfiniteScrollContainer';

import { getAvailableCouponList } from '../../services/coupon';
import { getApiDataByLang } from '../../utility';
import CouponItem from './couponItem';
import { CouponItemProps } from './interface';
import mainStyles from './myCoupon.module.scss';
import TransferCouponPopup from './transferCouponPopup';
import styles from './transferCoupons.module.scss';

type Props = {};

const TransferCoupons = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [data, setData] = React.useState<CouponItemProps[]>([]);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [transferPopupOpen, setTransferPopupOpen] =
    React.useState<boolean>(false);

  const convertData = (response: any, isNext: boolean) => {
    // console.log('convertData', response);
    setTotalCount(response.totalCount);
    const data = response.availableCouponList;
    if (data) {
      const temp = data.map((item: any) => {
        return {
          id: item._id,
          denomination: item.denomination,
          name: item.couponName ? getApiDataByLang(item.couponName) : '-',
          code: item.code,
          expiryDate: item.end_date,
          logo: item.logoImage,
          background: item.backgroundImage,
          backgroundColor: item.cardBackgroundColour,
          primaryColor: item.primaryTextColour,
          secondaryColor: item.secondaryTextColour,
          status: 'Available',
        };
      });
      if (!isNext) {
        setData(temp);
      } else {
        setData((prev: any) => [...prev, ...temp]);
      }
    }
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.target.checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((item) => item !== id));
    }
  };

  const handleSelectAll = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: any
  ) => {
    // select all function
    if (event.target.checked) {
      // Select all coupons
      const allIds = data.map((item: any) => item.id);
      setSelected(allIds);
    } else {
      // Deselect all coupons
      setSelected([]);
    }
  };

  return (
    <>
      <Header
        title={t('coupon.transferCoupons')}
        enableCloseButton
        closeButtonFunction={() => navigate('/my-coupon')}
      />
      <Grid
        container
        className={`${styles.container} ${mainStyles.container}`}
        id="scrollableDiv"
      >
        {data.length > 0 && (
          <>
            <div
              className={mainStyles.availableText}
            >{`${t('coupon.available')} (${totalCount})`}</div>
            <div className={styles.checkbox} style={{ paddingBottom: '20px' }}>
              <input
                type="checkbox"
                id="selectAll"
                name="selectAll"
                onChange={(e) => handleSelectAll(e, data)}
                checked={selected.length === data.length}
              />
              <label
                htmlFor="selectAll"
                style={{ paddingLeft: '26px', fontSize: '14px' }}
              >
                Select All
              </label>
            </div>
          </>
        )}
        <div style={{ width: '100%' }}>
          <InfiniteScrollContainer
            fetchData={getAvailableCouponList}
            returnData={convertData}
            list={data}
            pageSize={999}
            loadingSpinner={
              <Skeleton
                variant="rounded"
                height={146}
                style={{ marginBottom: '16px' }}
              />
            }
          >
            <div className={`${styles.couponContainer}`}>
              {data.length > 0 &&
                data.map((item) => {
                  const isChecked = selected.includes(item.id);
                  return (
                    <div className={styles.checkbox} key={`coupon-${item.id}`}>
                      <input
                        type="checkbox"
                        id={`transfer-${item.id}`}
                        name={`transfer-${item.id}`}
                        onChange={(e) => handleChange(e, item.id)}
                        checked={isChecked}
                      />
                      <label htmlFor={`transfer-${item.id}`}>
                        <CouponItem {...item} showBackground={false} />
                      </label>
                    </div>
                  );
                })}
            </div>
          </InfiniteScrollContainer>
        </div>
      </Grid>
      <Grid
        container
        className={`${styles.transferAction} ${selected.length > 0 ? styles.active : ''}`}
      >
        <RoundButton
          disabled={selected.length > 0 ? false : true}
          onClick={() => setTransferPopupOpen(true)}
        >{`${t('coupon.transfer')} (${selected.length})`}</RoundButton>
      </Grid>
      {data.length > 0 && (
        <TransferCouponPopup
          title={data[0].name}
          selected={selected}
          transferPopupOpen={transferPopupOpen}
          setTransferPopupOpen={setTransferPopupOpen}
        />
      )}
    </>
  );
};

export default TransferCoupons;
