import { useEffect, useRef, useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, AlertColor, Grid, Snackbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  LanguageDropdown,
  PageLoader,
  UploadFileField,
} from '../../components';
import Header from '../../components/header/header';
import InputField from '../../components/input-field';

import { ObjectKey } from '../../interfaces/common-interface';
import {
  createGroup,
  getGroupDetail,
  updateGroup,
  uploadCoverImage,
} from '../../services/partner-network';
import { FormValueType, useFormHook } from '../../utility/useFormHook';
import usePermissionHook from './hook/usePermissionHook';
import styles from './partnerNetwork.module.scss';

type Props = {};

const PartnerNetworkForm = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [failMsg, setFailMsg] = useState({
    open: false,
    status: 'error',
    message: '',
  });
  const { userState } = usePermissionHook();
  const isAdmin = Boolean(userState.permission?.SocialWallAdmin);
  const { id } = useParams();
  const editMode = Boolean(id);
  const descriptionLimit = 500;
  const descriptionTcLimit = 250;
  const formRef = useRef<HTMLFormElement | null>(null);
  const defaultFormValue: FormValueType = {
    cover: {
      value: null,
      isError: false,
      isRequired: true,
    },
    displayLanguage: {
      value: ['en'],
      isError: false,
      isRequired: true,
    },
    nameEN: {
      value: '',
      isError: false,
      isRequired: true,
    },
    descriptionEN: {
      value: '',
      isError: false,
      isRequired: true,
    },
    nameTC: {
      value: '',
      isError: false,
      isRequired: false,
    },
    descriptionTC: {
      value: '',
      isError: false,
      isRequired: false,
    },
  };
  const {
    isDirty,
    formValue,
    handleInputFieldChange,
    handleTextareaFieldChange,
    checkValidation,
    setInitFormValue,
  } = useFormHook({
    value: defaultFormValue,
  });

  useEffect(() => {
    if (!isAdmin) {
      sessionStorage.setItem(
        'snackbar',
        JSON.stringify({
          type: 'error',
          message: t('general.withoutPermissionMsg'),
        })
      );
      navigate('/partner-network');
    }
  }, []);

  useEffect(() => {
    const getDetail = async (groupId: string) => {
      try {
        const response = await getGroupDetail(groupId);
        if (
          response.status === 200 &&
          Object.keys(response.data.partnerNetworkGroup).length > 0
        ) {
          // console.log("response", response);
          const result = response.data.partnerNetworkGroup;
          setInitFormValue(
            {
              ...defaultFormValue,
              cover: {
                ...defaultFormValue.cover,
                value: {
                  fileName: result.coverImageUrl.split('/').pop(),
                  type: result.coverImageUrl.split('.').pop(),
                  data: result.coverImageUrl,
                  size: 0,
                },
              },
              displayLanguage: {
                ...defaultFormValue.displayLanguage,
                value: result.displayLanguage,
              },
              nameEN: {
                ...defaultFormValue.nameEN,
                value:
                  result.groupName.find(
                    (item: any) => item.lang.toLowerCase() === 'en'
                  )?.value ?? '',
                isRequired: result.displayLanguage.includes('en'),
              },
              descriptionEN: {
                ...defaultFormValue.descriptionEN,
                value:
                  result.description.find(
                    (item: any) => item.lang.toLowerCase() === 'en'
                  )?.value ?? '',
                isRequired: result.displayLanguage.includes('en'),
              },
              nameTC: {
                ...defaultFormValue.nameTC,
                value:
                  result.groupName.find(
                    (item: any) => item.lang.toLowerCase() === 'tc'
                  )?.value ?? '',
                isRequired: result.displayLanguage.includes('tc'),
              },
              descriptionTC: {
                ...defaultFormValue.descriptionTC,
                value:
                  result.description.find(
                    (item: any) => item.lang.toLowerCase() === 'tc'
                  )?.value ?? '',
                isRequired: result.displayLanguage.includes('tc'),
              },
            },
            true
          );
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    };
    if (id) {
      getDetail(id);
    }
  }, [id]);

  const convertFormValueToRequest = () => {
    const getFieldValue = (key: keyof FormValueType) => formValue[key].value;
    const groupNameList = getFieldValue('displayLanguage').map(
      (lang: string) => ({
        lang: lang,
        value: getFieldValue(`name${lang.toUpperCase()}`),
      })
    );
    const descriptionList = getFieldValue('displayLanguage').map(
      (lang: string) => ({
        lang: lang,
        value: getFieldValue(`description${lang.toUpperCase()}`),
      })
    );

    return {
      displayLanguage: getFieldValue('displayLanguage'),
      groupName: groupNameList,
      description: descriptionList,
      isHidden: false,
      coverImageUrl: getFieldValue('cover').data,
    };
  };

  const handleSubmit = async () => {
    const isValid = checkValidation();
    console.log('isValid', isValid);
    if (isValid) {
      setIsSaving(true);
      const request = convertFormValueToRequest();
      console.log(request);
      try {
        // console.log("handleSubmit");
        let response: ObjectKey;
        if (id) {
          response = await updateGroup({
            id: id,
            request: request,
          });
        } else {
          response = await createGroup(request);
        }
        // console.log("response", response);
        if (response.status === 200) {
          sessionStorage.setItem(
            'snackbar',
            JSON.stringify({
              type: 'success',
              message: id
                ? t('partnerNetwork.updateGroupSuccess')
                : t('partnerNetwork.createGroupSuccess'),
              id: response.data.partnerNetworkGroup._id,
            })
          );
          navigate(`/partner-network`);
        } else {
          setFailMsg((prev) => ({
            ...prev,
            open: true,
            message: t('partnerNetwork.createGroupFail'),
          }));
        }
      } catch (error) {
        setFailMsg((prev) => ({
          ...prev,
          open: true,
          message: t('partnerNetwork.createGroupFail'),
        }));
        throw error;
      } finally {
        setIsLoading(false);
      }
    } else {
      formRef.current
        ?.querySelector('*[data-error="true"]')
        ?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleFileFieldChange = async (
    name: string,
    value: File | File[] | null
  ) => {
    if (!value) return false;
    try {
      if (!Array.isArray(value)) {
        const response = await uploadCoverImage({
          image: value,
        });
        if (response.status === 200) {
          // console.log("response", response);
          const imageLink = response.data.imageUrl;
          const fileValue = {
            fileName: imageLink.split('/').pop(),
            type: value.type,
            data: imageLink,
            size: value.size,
          };
          handleInputFieldChange(name, fileValue);
          setIsLoading(false);
        }
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleChangeDisplayLanguage = (selected: Array<string>) => {
    const haveEN = selected.some((lang: string) => lang.toLowerCase() === 'en');
    const haveTC = selected.some((lang: string) => lang.toLowerCase() === 'tc');

    const updateFormValue = {
      ...formValue,
      displayLanguage: {
        ...formValue.displayLanguage,
        value: selected,
      },
      nameEN: {
        ...formValue.nameEN,
        isRequired: haveEN,
      },
      descriptionEN: {
        ...formValue.descriptionEN,
        isRequired: haveEN,
      },
      nameTC: {
        ...formValue.nameTC,
        isRequired: haveTC,
      },
      descriptionTC: {
        ...formValue.descriptionTC,
        isRequired: haveTC,
      },
    };

    setInitFormValue(updateFormValue);
  };

  return (
    <>
      <Header
        title={
          !editMode
            ? t('partnerNetwork.newGroup')
            : t('partnerNetwork.editPartnerNetwork')
        }
        enableCloseButton
        // closeButtonNavigation="/partner-network"
        closeButtonFunction={() => {
          navigate(-1);
        }}
      >
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item xs="auto">
            <LoadingButton
              disabled={!isDirty}
              disableRipple
              loading={isSaving}
              className={styles.saveButton}
              variant="text"
              onClick={handleSubmit}
            >
              {!editMode ? t('homepage.createPopup.title') : t('general.save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Header>
      <form ref={formRef} className={styles.formContainer} id="CreateGroup">
        <UploadFileField
          id="cover"
          name="cover"
          label={t('event.coverImageLabel')}
          helperText={t('event.coverImageErrorText', {
            width: 1125,
            height: 632,
          })}
          value={formValue.cover.value}
          error={formValue.cover.isError}
          required={formValue.cover.isRequired}
          accept="image/*"
          limitSize={5 * 1000 * 1000}
          onChange={(files) => {
            setIsLoading(true);
            handleFileFieldChange('cover', files);
          }}
          onRemove={(files) => {
            handleInputFieldChange('cover', files);
          }}
          dimension={{ width: 1125, height: 632 }}
          disableDimensionChecking
          enableEdit={true}
          optionsTitle={t('myProfile.editProfile.editCoverPhoto')}
        />
        <div className={styles.formWrapper}>
          {process.env.REACT_APP_LOCATION !== 'PHL' && (
            <div className={styles.formField}>
              <LanguageDropdown
                value={formValue.displayLanguage.value}
                onChange={handleChangeDisplayLanguage}
              />
            </div>
          )}
          {formValue.displayLanguage.value.includes('en') && (
            <>
              <div className={styles.formField}>
                <InputField
                  id="nameEN"
                  name="nameEN"
                  placeholder={t('event.eventNameEnLabel')}
                  value={formValue.nameEN.value}
                  error={formValue.nameEN.isError}
                  required={formValue.nameEN.isRequired}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleInputFieldChange(name, value);
                  }}
                />
              </div>
              <div className={styles.formField}>
                <InputField
                  id="descriptionEN"
                  name="descriptionEN"
                  placeholder={t('event.descriptionEnLabel')}
                  value={formValue.descriptionEN.value}
                  error={formValue.descriptionEN.isError}
                  required={formValue.descriptionEN.isRequired}
                  multiline
                  helperText={
                    <Grid container flexWrap="nowrap">
                      {formValue.descriptionEN.isError &&
                        formValue.descriptionEN.value > descriptionLimit && (
                          <Grid item xs="auto" flexShrink={1}>
                            <Typography variant="body2" align="left">
                              {t('event.descriptionWordCountOverHelperText')}
                            </Typography>
                          </Grid>
                        )}
                      <Grid item xs>
                        <Typography variant="body2" align="right">
                          {`${formValue.descriptionEN.value.length}/${descriptionLimit}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleTextareaFieldChange(name, value, descriptionLimit);
                  }}
                />
              </div>
            </>
          )}
          {formValue.displayLanguage.value.includes('tc') && (
            <>
              <div className={styles.formField}>
                <InputField
                  id="nameTC"
                  name="nameTC"
                  placeholder={t('event.eventNameTcLabel')}
                  value={formValue.nameTC.value}
                  error={formValue.nameTC.isError}
                  required={formValue.nameTC.isRequired}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleInputFieldChange(name, value);
                  }}
                />
              </div>
              <div className={styles.formField}>
                <InputField
                  id="descriptionTC"
                  name="descriptionTC"
                  placeholder={t('event.descriptionTcLabel')}
                  value={formValue.descriptionTC.value}
                  error={formValue.descriptionTC.isError}
                  required={formValue.descriptionTC.isRequired}
                  multiline
                  helperText={
                    <Grid container>
                      {formValue.descriptionTC.isError &&
                        formValue.descriptionTC.value > descriptionTcLimit && (
                          <Grid item xs="auto" flexShrink={1}>
                            <Typography variant="body2" align="left">
                              {t('event.descriptionWordCountOverHelperText')}
                            </Typography>
                          </Grid>
                        )}
                      <Grid item xs>
                        <Typography variant="body2" align="right">
                          {`${formValue.descriptionTC.value.length}/${descriptionTcLimit}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleTextareaFieldChange(name, value, descriptionTcLimit);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </form>
      {isLoading && <PageLoader />}
      <Snackbar
        open={failMsg.open}
        autoHideDuration={6000}
        onClose={() => setFailMsg((prev) => ({ ...prev, open: false }))}
      >
        <Alert severity={failMsg.status as AlertColor}>{failMsg.message}</Alert>
      </Snackbar>
    </>
  );
};

export default PartnerNetworkForm;
