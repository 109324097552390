import axios, { AxiosInstance, AxiosPromise } from 'axios';
import { buildWebStorage, setupCache } from 'axios-cache-interceptor';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';

import { graphConfig } from '../config/authConfig';
import { ObjectKey } from '../interfaces/common-interface';
import i18n from '../languages';
import { setUserState } from '../reducers/user-slice';
import { store } from '../store/store';
import { handleLogoutAction } from '../utility';

const { dispatch } = store;

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem('ACCESS_TOKEN') ?? '';
let retryCount = 3;

const Axios = axios.create({
  baseURL: API_DOMAIN,
  headers: {
    Authorization: `Bearer ${token}`,
    'Cache-Control': 'max-age=600', // 10 minutes cache duration
    'Content-Type': 'application/json',
    language: i18n.use(LanguageDetector).language.includes('en') ? 'en' : 'tc',
  },
});

const searchAxios = axios.create({
  baseURL: API_DOMAIN,
  headers: {
    Authorization: `Bearer ${token}`,
    'Cache-Control': 'max-age=600', // 10 minutes cache duration
    'Content-Type': 'application/json',
    language: i18n.use(LanguageDetector).language.includes('en') ? 'en' : 'tc',
  },
});

export const instance = setupCache(Axios, {
  storage: buildWebStorage(sessionStorage, 'cache-'),
  methods: ['get'],
  ttl: parseInt(process.env.REACT_APP_DEFAULT_API_CACHE_TIME as string),
});

export const searchInstance = setupCache(searchAxios, {
  storage: buildWebStorage(sessionStorage, 'search-cache-'),
  methods: ['get'],
  ttl: parseInt(process.env.REACT_APP_DEFAULT_API_CACHE_TIME as string),
});

export const publicInstance = axios.create({
  baseURL: API_DOMAIN,
  headers: {
    'Content-Type': 'application/json',
    language: i18n.use(LanguageDetector).language.includes('en') ? 'en' : 'tc',
  },
});

publicInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  async function (error) {
    return Promise.reject(error);
  }
);

const login_instance = axios.create({
  baseURL: API_DOMAIN,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
  },
});

login_instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  async function (error) {
    // const prevRequest = error?.config;
    // if (error.response && error.response.status === 401) {
    //   retryCount--;
    //   if (retryCount <= 0) {
    //     localStorage.removeItem("ACCESS_TOKEN");
    //     localStorage.removeItem("REFRESH_TOKEN");
    //     localStorage.removeItem("IS_ACCEPTED_TNC");
    //     dispatch(setUserState({}));
    //     const initLocation = getInitLocationUseWindowLocation();
    //     window.location.href = "/login?initLocation=" + initLocation;
    //   } else {
    //     login_instance(prevRequest);
    //   }
    // } else {
    //   return Promise.reject(error);
    // }
    console.log('login_instance error stringify', JSON.stringify(error));
    console.log('login_instance error', error);

    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  (config) => {
    if (config.headers['X-Pause-Request']) {
      return new Promise((reject) => {
        reject(config);
      });
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    const actionTimestamp = localStorage.getItem('ACTION_STAMP');
    if (
      !actionTimestamp ||
      moment().isAfter(moment.unix(parseInt(actionTimestamp)), 'day')
    ) {
      addDailyCherries();
    }

    // if (localStorage.getItem("ACCESS_TOKEN")) {
    //   localStorage.removeItem("ACCESS_TOKEN");
    // }
    // if (localStorage.getItem("REFRESH_TOKEN")) {
    //   localStorage.removeItem("REFRESH_TOKEN");
    // }
    // if (localStorage.getItem("IS_ACCEPTED_TNC")) {
    //   localStorage.removeItem("IS_ACCEPTED_TNC");
    // }
    localStorage.setItem('ACTION_STAMP', moment().unix().toString());
    return response.data;
  },
  async function (error) {
    const prevRequest = error?.config;
    if (
      error.response &&
      error.response.data.status === 4001
      //   error.config &&
      //   !error.config.__isRetryRequest
      //refresh token
    ) {
      console.log('instance stringify', JSON.stringify(error));
      console.log('instance', error);
      await handleRefreshTokenBehavior(prevRequest, instance);
      // if (
      //   prevRequest.headers["X-Pause-Request"] ||
      //   localStorage.getItem("isRefresh")
      // ) {
      //   if (
      //     prevRequest.headers["Authorization"] ===
      //     `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`
      //   ) {
      //     prevRequest.headers["X-Pause-Request"] = true;
      //     return await instance(prevRequest);
      //   } else {
      //     prevRequest.headers["Authorization"] = `Bearer ${localStorage.getItem(
      //       "ACCESS_TOKEN"
      //     )}`;
      //     delete prevRequest.headers["X-Pause-Request"];
      //     return await instance(prevRequest);
      //   }
      // } else {
      //   return await handleRefreshTokenBehavior(prevRequest, instance);
      // }
    } else if (
      error.response.data.status === 4085 ||
      error.response.data.status === 4002
    ) {
      handleLogoutAction();
    } else {
      return Promise.reject(error);
    }
  }
);

const handleRefreshTokenBehavior = async (
  prevRequest: ObjectKey,
  apiInstance: AxiosInstance
) => {
  let refreshToken = '';
  const stateRefreshToken = store.getState().userState.refreshToken;
  const storageRefreshToken = localStorage.getItem('REFRESH_TOKEN');
  if (stateRefreshToken) {
    refreshToken = stateRefreshToken;
  } else if (storageRefreshToken) {
    refreshToken = storageRefreshToken;
  }
  if (refreshToken !== '') {
    localStorage.setItem('isRefresh', 'true');
    return await getNewAccessTokenByRefreshToken(
      refreshToken,
      prevRequest,
      apiInstance
    );
  } else if (refreshToken === '' && !localStorage.getItem('isRefresh')) {
    const initLocation = getInitLocationUseWindowLocation();
    // window.location.href = "/login?initLocation=" + initLocation;
    handleLogoutAction('/login?initLocation=' + initLocation); //need determine PHL/TWN
  }
};

let controller: AbortController;

searchInstance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    if (controller) {
      // Cancel the previous request before making a new request
      controller.abort();
    }
    // Create a new AbortController
    controller = new AbortController();
    config.signal = controller.signal;

    if (config.headers['X-Pause-Request']) {
      return new Promise((reject) => {
        reject(config);
      });
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

searchInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const prevRequest = error?.config;
    // console.log("searchInstance stringify", JSON.stringify(error));
    // console.log("searchInstance", error);

    if (error.code === 'ERR_CANCELED') {
      return Promise.resolve(error);
    }

    if (error.response && error.response.data.status === 4001) {
      if (
        prevRequest.headers['X-Pause-Request'] ||
        localStorage.getItem('isRefresh')
      ) {
        if (
          prevRequest.headers['Authorization'] ===
          `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
        ) {
          prevRequest.headers['X-Pause-Request'] = true;
          return await searchInstance(prevRequest);
        } else {
          prevRequest.headers['Authorization'] = `Bearer ${localStorage.getItem(
            'ACCESS_TOKEN'
          )}`;
          delete prevRequest.headers['X-Pause-Request'];
          return await searchInstance(prevRequest);
        }
      } else {
        return await handleRefreshTokenBehavior(prevRequest, searchInstance);
      }
    } else if (
      error.response?.data?.status === 4085 ||
      error.response?.data?.status === 4002
    ) {
      handleLogoutAction();
    } else {
      return Promise.reject(error);
    }
  }
);

const getNewAccessTokenByRefreshToken = async (
  refreshToken: string,
  prevRequest: ObjectKey,
  apiInstance: AxiosInstance
) => {
  try {
    const response = await postRefreshToken({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    });
    console.log('postRefreshToken', JSON.stringify(response));
    if (response.status === 200) {
      localStorage.removeItem('isRefresh');
      delete instance.defaults.headers['X-Pause-Request'];
      delete searchInstance.defaults.headers['X-Pause-Request'];
      setAuthorizationToken(response.data.access_token);
      const existUserSate = store.getState().userState;
      dispatch(
        setUserState({
          ...existUserSate,
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
          isAcceptedTnc: response.data.isAcceptedTnc,
        })
      );

      prevRequest.headers['Authorization'] =
        `Bearer ${response.data.access_token}`;
      return await apiInstance(prevRequest);
    } else {
      await checkRetryRefreshTokenCount(refreshToken, prevRequest, apiInstance);
    }
  } catch (error: any) {
    console.log('postRefreshToken', JSON.stringify(error));
    if (error.response.data.status === 4001) {
      await checkRetryRefreshTokenCount(refreshToken, prevRequest, apiInstance);
    } else {
      handleLogoutAction();
    }
  }
};

const checkRetryRefreshTokenCount = async (
  refreshToken: string,
  prevRequest: ObjectKey,
  apiInstance: AxiosInstance
) => {
  retryCount -= 1;
  console.log('retryCount', retryCount);
  if (retryCount > 0) {
    await getNewAccessTokenByRefreshToken(
      refreshToken,
      prevRequest,
      apiInstance
    );
  } else {
    const initLocation = getInitLocationUseWindowLocation();
    retryCount = 3;
    handleLogoutAction('/login?initLocation=' + initLocation); //need determine PHL/TWN
  }
};

const getInitLocationUseWindowLocation = () => {
  let pathnameWithParams = window.location.href.replace(
    window.location.origin,
    ''
  );

  if (pathnameWithParams.includes('initLocation=')) {
    const initLocation = pathnameWithParams.split('initLocation=')[1];
    return initLocation;
  }
  return pathnameWithParams;
};

export async function getMsGraph(accessToken: string) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

export const setAuthorizationToken = async (authorizationToken: string) => {
  if (authorizationToken) {
    // await instance.interceptors.request.use((request) => {
    //   console.log("before request", request);
    //   if (request && request.headers) {
    //     request.headers.Authorization = `Bearer ${authorizationToken}`;
    //   }
    //   console.log("after request", request);
    //   return request;
    // });
    instance.defaults.headers.Authorization = `Bearer ${authorizationToken}`;
    // await searchInstance.interceptors.request.use((request) => {
    //   if (request && request.headers) {
    //     request.headers.Authorization = `Bearer ${authorizationToken}`;
    //   }
    //   return request;
    // });
    searchInstance.defaults.headers.Authorization = `Bearer ${authorizationToken}`;
  }
};

export const setInstanceLanguage = (lang: string) => {
  // instance.interceptors.request.use((request) => {
  //   if (request && request.headers) {
  //     request.headers.language = lang;
  //   }
  //   return request;
  // });
  instance.defaults.headers.language = lang;
  // searchInstance.interceptors.request.use((request) => {
  //   if (request && request.headers) {
  //     request.headers.language = lang;
  //   }
  //   return request;
  // });
  searchInstance.defaults.headers.language = lang;
};

export const postSSOAuthCodeHK = (input: {
  code: string;
  redirect_uri: string;
}): AxiosPromise =>
  login_instance({
    method: 'POST',
    data: input,
    url: `/iam/app/hk/ssologin`,
  });

export const postUserLoginForDEV = (input: {
  grant_type: string;
  userId: string;
  password: string;
}): AxiosPromise =>
  login_instance({
    method: 'POST',
    data: input,
    url: `/iam/app/login`,
  });

export const postUserLoginTW = (input: {
  grant_type: string;
  userId: string;
  password: string;
}): AxiosPromise =>
  login_instance({
    method: 'POST',
    data: input,
    url: `/iam/app/tw/login`,
  });

export const postUserLoginSSO = (input: {
  token_type: string;
  scope: string[];
  expires_in: number;
  ext_expires_in: number;
  access_token: string;
}): AxiosPromise =>
  login_instance({
    method: 'POST',
    data: input,
    url: `/iam/app/${
      process.env.REACT_APP_LOCATION === 'PHL' ? 'ph' : 'hk'
    }/login`,
  });

export const postRefreshToken = (input: {
  grant_type: string;
  refresh_token: string;
}): AxiosPromise =>
  login_instance({
    method: 'POST',
    data: input,
    url: `/iam/app/login`,
  });

export const postUserLogout = (input: {
  MS_TOKEN: string | null;
}): AxiosPromise =>
  instance({
    method: 'POST',
    url: `/iam/App/logout`,
    data: input,
  });

export const addDailyCherries = (): AxiosPromise =>
  searchInstance({
    method: 'POST',
    url: `cherries-and-ecosystem/addDailyCherries`,
  });

export const getPermission = (): AxiosPromise =>
  instance({
    method: 'GET',
    id: 'getPermission',
    url: `/iam/app/permission`,
  });

export const acceptTnc = (): AxiosPromise =>
  instance({
    method: 'POST',
    url: `/iam/App/acceptTnc`,
  });
