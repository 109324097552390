import { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';
import {
  Alert,
  Avatar,
  Box,
  Grid,
  Link,
  Skeleton,
  Snackbar,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import { BottomNavMenu, ShortcutMenu, Toolbar } from '../../components';

import { ObjectKey } from '../../interfaces/common-interface';
import { UserState } from '../../reducers/user-slice';
import { getSocialWallConfig } from '../../services/social-wall';
import { RootState } from '../../store/store';
import EventSections from './eventSections';
import styles from './homepage.module.scss';
import SocialWallSections from './socialWallSection';

import 'swiper/css';
import 'swiper/css/pagination';

import CrazyAd from '../../components/crazy-ad';
import ReceiveCouponPopup from '../../components/receive-coupon-popup/receive-coupon-popup';

import {
  getCarouselBannerList,
  getCrazyAd,
  getQuickAccessHomeList,
} from '../../services/homepage';
import { getApiDataByLang, isHKStyle } from '../../utility';
import NewsSupportsSection from './newsSupportsSection';
import PartnerNetworkSection from './partnerNetworkSection';
import StoreAndFinancialUpdatesSection from './storeAndFinancialUpdatesSection';

function Homepage(props: any) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState({ banner: false, shortcut: false });
  const [socialWallPermission, setSocialWallPermission] = useState<ObjectKey>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { accounts } = useMsal();
  const navigate = useNavigate();

  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [crazyAdSrc, setCrazyAdSrc] = useState<{ url: string; id: string }>({
    url: '',
    id: '',
  });
  const [receiveCouponPopupOpen, setReceiveCouponOpen] = useState(true);
  const [carouselBannerItems, setCarouselBannerItems] = useState<Array<any>>(
    []
  );
  const [shortcutItems, setShortcutItems] = useState<Array<any>>([]);
  localStorage.setItem('LOAD_INBOX_MESSAGE', 'false');

  const [SnackbarOpen, setSnackbarOpen] = useState(false);
  // console.log('userState', userState);
  useEffect(() => {
    if (searchParams.get('request') === 'LOGIN_SUCCESS') {
      // console.log(searchParams.get('request'));
      // console.log('accounts', accounts);
      // if (accounts.length > 0) {
      //   localStorage.setItem('REQUEST_ACCOUNT', JSON.stringify(accounts));
      // }
      setSnackbarOpen(true);
      let params = new URLSearchParams(searchParams);
      params.delete('request');
      setSearchParams(params);
    }
  }, [searchParams]);

  useEffect(() => {
    setLoading((pre) => ({ ...pre, banner: true, shortcut: true }));
    const getConfig = async () => {
      const response = await getSocialWallConfig();
      setSocialWallPermission(response.data);
    };
    const getBannerList = async () => {
      try {
        const response: any = await getCarouselBannerList();
        // console.log("getBannerList", response);
        if (response.data && response.data.carouselBannerList.length > 0) {
          response.data.carouselBannerList.sort((a: any, b: any) => {
            return a.sequence - b.sequence;
          });
          response.data.carouselBannerList.forEach((item: any) => {
            const temp = {
              title: item.title,
              image: item.displayImage,
              isDisplayOnly: !item.isClickable,
              url: item.url,
              isOpenNewTab: item.isOpenNewTab,
              sequence: item.sequence,
            };
            // console.log(temp);
            setCarouselBannerItems((prev: any) => [...prev, temp]);
          });
        }
        setLoading((pre) => ({ ...pre, banner: false }));
      } catch (error) {
        setLoading((pre) => ({ ...pre, banner: false }));
        console.log(error);
      }
    };
    const getShortcutHomeList = async () => {
      try {
        const response: any = await getQuickAccessHomeList();
        // console.log("getShortcutHomeList", response.data.gridSeq);
        if (response.data && response.data.gridSeq.length > 0) {
          response.data.gridSeq.forEach((item: any) => {
            const temp = {
              image: item.displayImage,
              text: getApiDataByLang(item.quickAccessName),
              url: item.linkTo,
              isOpenNewTab: item.isOpenNewTab,
              disabled: !item.isDisplayInHome,
            };
            // console.log(temp);
            setShortcutItems((prev: any) => [...prev, temp]);
          });
        }
        setLoading((pre) => ({ ...pre, shortcut: false }));
      } catch (error) {
        setLoading((pre) => ({ ...pre, shortcut: false }));
        console.log(error);
      }
    };
    const getCrazyAdData = async () => {
      try {
        const response = await getCrazyAd();
        // console.log('getCrazyAd', Object.keys(response.data).length);
        if (Object.keys(response.data).length > 0) {
          const PrevImageId = localStorage.getItem('CRAZY_AD_Id');
          if (PrevImageId !== response.data._id) {
            localStorage.removeItem('CRAZY_AD_READ');
          }
          setCrazyAdSrc({
            url: response.data.displayImage,
            id: response.data._id,
          });
        } else {
          localStorage.setItem('CRAZY_AD_READ', 'true');
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCrazyAdData();
    getBannerList();
    getShortcutHomeList();
    setTimeout(() => {
      getConfig();
    }, 1000);
  }, []);

  return (
    <Box className={styles.pageWrapper}>
      {localStorage.getItem('CRAZY_AD_READ') !== 'true' && (
        <CrazyAd defaultCountDown={3} imageSrc={crazyAdSrc} />
      )}

      <Box className={styles.headerSection}>
        <img
          className={styles.bgLogo}
          src="/assets/images/home_banner_bg_logo.png"
          alt=""
        />
        <Toolbar config={socialWallPermission} />
        <div
          className={`${styles.infoContainer} ${isHKStyle() ? styles.hkStyle : ''}`}
        >
          <Avatar
            className={`${styles.profileIcon} ${isHKStyle() ? styles.hkStyle : ''}`}
            alt={userState.name}
            src={userState.avatar ?? undefined}
            onClick={() => navigate('/me/my-profile')}
          />
          <Typography className={styles.greetingTitle} variant="h3">
            {t('homepage.userName', {
              name:
                process.env.REACT_APP_LOCATION === 'PHL'
                  ? userState.firstName
                  : userState.name,
            })}
          </Typography>
          <Typography className={styles.greetingCaption} variant="body2">
            {t('homepage.welcomeMsg')}
          </Typography>
        </div>
      </Box>
      {!loading.banner ? (
        <>
          {carouselBannerItems.length > 0 && (
            <Box
              className={`${styles.swiperContainer} ${isHKStyle() ? styles.hkStyle : ''}`}
            >
              <Swiper
                slidesPerView={'auto'}
                centeredSlides={true}
                spaceBetween={isHKStyle() ? -20 : -14}
                autoHeight={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
              >
                {carouselBannerItems.map((banner: any, index: number) => (
                  <SwiperSlide key={index}>
                    {banner.isDisplayOnly ? (
                      <Box className={styles.swiperItem}>
                        <img src={banner.image} alt={banner.title} />
                      </Box>
                    ) : (
                      <Link
                        href={banner.url}
                        className={styles.swiperItem}
                        {...(banner.isOpenNewTab && {
                          target: '_blank',
                          rel: 'noopener noreferrer',
                        })}
                      >
                        <img src={banner.image} alt={banner.title} />
                      </Link>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          )}
        </>
      ) : (
        <div className={styles.swiperContainer} style={{ padding: '16px' }}>
          <Skeleton variant="rounded" width="100%" height={144} />
        </div>
      )}

      <Box className={styles.mainSection}>
        {/* <ShortcutMenu setOpenUsefulLinksPopup={setOpenUsefulLinksPopup} /> */}
        {!loading.shortcut ? (
          <ShortcutMenu data={shortcutItems} showMore={true} />
        ) : (
          <Grid container gap="8px" py="5px">
            {Array.from({ length: 2 }).map((_, index) => {
              return (
                <Grid container spacing={1} px="16px">
                  {Array.from({ length: 4 }).map((_, index) => (
                    <Grid item xs={3}>
                      <Skeleton key={index} variant="rectangular" height={63} />
                    </Grid>
                  ))}
                </Grid>
              );
            })}
          </Grid>
        )}
        {process.env.REACT_APP_LOCATION !== 'TWN' && (
          <>
            <PartnerNetworkSection />
            <NewsSupportsSection />
          </>
        )}

        {process.env.REACT_APP_LOCATION !== 'HK' && (
          <SocialWallSections socialWallPermission={socialWallPermission} />
        )}
        {process.env.REACT_APP_LOCATION !== 'HK' && <EventSections />}

        {process.env.REACT_APP_LOCATION === 'HK' && (
          <StoreAndFinancialUpdatesSection />
        )}
      </Box>
      <BottomNavMenu />
      {process.env.REACT_APP_LOCATION === 'HK' && <ReceiveCouponPopup />}
      {process.env.REACT_APP_LOCATION === 'HK' && (
        <Snackbar
          open={SnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert severity="success">{`Teams Login Success`}</Alert>
        </Snackbar>
      )}
    </Box>
  );
}

export default Homepage;
