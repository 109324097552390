import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Popup, RoundButton } from '../../components';

import {
  confirmCoupons,
  getCouponsConfirmationCount,
} from '../../services/coupon';
import styles from './receive-coupon-popup.module.scss';

type Props = {
  // couponLength: number;
  // isOpen: boolean;
  // setIsOpen: (close: boolean) => void;
  // onConfirmClick?: () => void;
};

const ReceiveCouponPopup = (props: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [couponLength, setCouponLength] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  // const { couponLength, isOpen, setIsOpen, onConfirmClick } = props;
  const { t } = useTranslation();

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await getCouponsConfirmationCount();
        // console.log('getCouponsConfirmationCount', response);
        if (response.status === 200) {
          const count = response.data.couponsUnConfirmationCount;
          if (count > 0) {
            setIsOpen(true);
            setCouponLength(count);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [navigate]);

  const handleConfirmClick = async () => {
    try {
      const response = await confirmCoupons();
      console.log('response', response);
      window.location.reload();
    } catch (err) {
      console.error(err);
    } finally {
      setIsOpen(false);
    }
  };

  return (
    <Popup
      id={styles.receiveCouponPopup}
      isOpen={isOpen}
      setIsOpen={(close: boolean) => {
        // setIsOpen(close);
      }}
      title={
        <div className={styles.header}>
          <img src="/assets/images/receive_coupon.svg" alt="confirm receive" />
        </div>
      }
      content={
        <Box className={styles.content}>
          <div className={styles.title}>{t('coupon.receivedCouponPopup')}</div>
          <div className={styles.text}>
            {t('coupon.receivedCouponContent', { number: couponLength })}
          </div>
          <RoundButton onClick={handleConfirmClick}>
            {t('general.confirm')}
          </RoundButton>
        </Box>
      }
      disableActions={true}
    />
  );
};

export default ReceiveCouponPopup;
