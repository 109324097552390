import { FC, useEffect, useState } from 'react';

import {
  Alert,
  Box,
  Grid,
  IconButton,
  Link,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  LanguageDropdown,
  Popup,
  QuestionField,
  ToggleButton,
} from '../../components';
import DateTimePickerField from '../../components/datetime-picker-field';
import Header from '../../components/header/header';
import InputField from '../../components/input-field';
import InvitationScopeField from '../../components/invitation-scope-field';
import SelectField from '../../components/select-field';
import UploadFileField from '../../components/upload-file-field';

import { ObjectKey } from '../../interfaces/common-interface';
import {
  getInvitationGroupList,
  getStaffDetail,
  getStaffFilterList,
  getStaffList,
} from '../../services/common';
import {
  deleteSurvey,
  getSurvey,
  getSurveyTemplate,
  postCreateSurvey,
  postEditSurvey,
} from '../../services/survey';
import { convertStaffList } from '../../utility';
import { checkArrayOfObjectHasField } from '../../utility/checkArrayOfOjectHasField';
import styles from './surveyCreate.module.scss';
import SurveyForm from './surveyForm';
import { FormValueType, useSurveyFormHook } from './useSurveyFormHook';

interface SurveyCreateProps {
  mode?: 'create' | 'edit';
  id?: string;
  onBack?: () => void;
  submitCallback?: (id: string, name: ObjectKey) => void;
}
export const mcAndRankOrderQuestionTypeId = [
  '6448afac1dde4cea6034cda0',
  '6448afcf45a431c8981ff6d5',
] as const;
const SurveyCreate: FC<SurveyCreateProps> = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [status, setStatus] = useState('');
  const navigate = useNavigate();
  const descriptionLimit = 200;
  const descriptionTcLimit = 100;
  const respondentsGroupList: never[] = [];
  const respondentsList = [
    {
      id: 'public',
      name: t('general.allPartners'),
      displayName: t('general.allPartners'),
      description: '',
    },
    {
      id: 'private',
      name: t('general.private'),
      displayName: t('general.private'),
      description: t('general.privateDesc'),
      isNextStep: true,
      isOpened: false,
      nextStepId: 'invitationScopeInputWrapper',
    },
  ];
  const {
    isDirty,
    formValue,
    handleInputFieldChange,
    handleTextareaFieldChange,
    handleChangeLang,
    handleToggleButtonChange,
    checkValidation,
    convertFormValueToRequest,
    uploadImage,
    setInitFormValue,
  } = useSurveyFormHook();
  const [showAudiencePopup, setShowAudiencePopup] = useState<boolean>(false);
  const [openDeleteSurveyPopup, setOpenDeleteSurveyPopup] = useState(false);
  const [openPreviewPopup, setOpenPreviewPopup] = useState(false);
  // const [dropdownList, setDropdownList] = useState<{
  //   [key: string]: Array<any>;
  // }>({
  //   respondentsList: [],
  //   respondentsGroupList: [],
  // });

  const editMode = Boolean(id);
  const [optionDesc, setOptionDesc] = useState(null);
  const defaultValue = {
    questionType: {
      value: '',
      isError: false,
      isRequired: true,
    },
    question: {
      value: '',
      isError: false,
      isRequired: true,
    },
    options: {
      value: [
        {
          id: 'question-1-option-1',
          index: 0,
          value: '',
        },
        {
          id: 'question-1-option-2',
          index: 1,
          value: '',
        },
      ],

      isError: false,
      isRequired: false,
    },
    required: {
      value: false,
      isError: false,
      isRequired: false,
    },
    allowMultipleSelection: {
      value: false,
      isError: false,
      isRequired: false,
    },
    multipleSelection: {
      value: '',
      isError: false,
      isRequired: false,
    },
    answerNumbers: {
      value: '',
      isError: false,
      isRequired: false,
    },
    opinionScale: {
      value: '',
      isError: false,
      isRequired: false,
    },
    highScoreLabel: {
      value: '',
      isError: false,
      isRequired: false,
    },
    lowScoreLabel: {
      value: '',
      isError: false,
      isRequired: false,
    },
    uploadPhotos: {
      value: null,
      isError: false,
      isRequired: false,
    },
  };
  const [failMsg, setFailMsg] = useState({
    open: false,
    message: '',
  });
  const getSubmitData = (data: any) => {
    // console.log(data);
    setOptionDesc(data.data);
    setShowAudiencePopup(data.isOpen);
  };
  const getIsClosed = (data: boolean) => {
    // console.log(data);
    setShowAudiencePopup(data);
  };

  const handleSubmit = async () => {
    const isValid = checkValidation();
    if (isValid) {
      setIsLoading(true);

      if (props.mode === 'create') {
        // const imageData = await uploadImage();
        const request = await convertFormValueToRequest();
        // console.log('postCreateSurvey', { ...request, ...imageData });
        postCreateSurvey(request)
          .then((res) => {
            setIsLoading(false);
            if (props.submitCallback) {
              props.submitCallback(
                res.data.surveyTemplate._id,
                res.data.surveyTemplate.surveyTitle
              );
            } else {
              localStorage.setItem('survey-created', 'true');
              navigate('/survey');
            }
          })
          .catch((err) => {
            setFailMsg({
              open: true,
              message: err.response.message ?? t('general.errorMsg'),
            });
            setIsLoading(false);
          });
      } else if (props.mode === 'edit') {
        // //console.log("edit survey", formValue.cover);
        // const imageData = await uploadImage(); //to check is the image is changed
        const request = await convertFormValueToRequest();
        const surveyId = props.id ?? id;
        postEditSurvey(surveyId as string, request)
          .then((res) => {
            setIsLoading(false);
            if (props.submitCallback) {
              props.submitCallback(
                res.data.surveyTemplate._id,
                res.data.surveyTemplate.surveyTitle
              );
            } else {
              navigate('/survey');
            }
          })
          .catch((err) => {
            setFailMsg({
              open: true,
              message: err.response.message ?? t('general.errorMsg'),
            });
            setIsLoading(false);
          });
        setIsLoading(false);
      }
    }
  };

  const handlePreview = () => {
    const isValid = checkValidation();
    if (isValid) {
      setOpenPreviewPopup(true);
    }
  };

  const handleDeleteSurvey = () => {
    setOpenDeleteSurveyPopup(true);
    deleteSurvey(id as string).then((res) => {
      setIsLoading(false);
      navigate('/survey');
    });
  };

  const convertOptionsToFormData = (data: Array<any>, index: number) => {
    // console.log("🚀 ~ convertOptionsToFormData ~ data:", data);
    const options = data.map((item: any, optionIndex: number) => {
      if (item.isOthers) {
        return {
          id: `question-${index + 1}-option-Other`,
          index: optionIndex,
          placeholder: `${t('survey.create.other')}`,
          value: 'Others',
          isOther: true,
          file: {
            value: item.imageName ? item.imageName : null,
            isError: false,
            isRequired: true,
          },
        };
      }
      return {
        id: `question-${index + 1}-option-${optionIndex + 1}`,
        index: optionIndex,
        value: item.value,
        isOther: false,
        file: {
          value: item.imageName ? item.imageName : null,
          isError: false,
          isRequired: false,
        },
      };
    });

    // console.log("🚀 ~ convertOptionsToFormData ~ options:", options);
    return options;
  };
  const convertToFormData = (data: Array<FormValueType>) => {
    const convertedData = data.map((item: any, index: number) => {
      return {
        ...defaultValue,
        questionType: {
          ...defaultValue.questionType,
          value: item.questionType._id,
        },
        question: {
          ...defaultValue.question,
          value: item.question,
        },
        options: {
          ...defaultValue.options,
          value:
            item.answerChoices.length > 0
              ? convertOptionsToFormData(item.answerChoices, index)
              : item.orderAnswers.length > 0
                ? convertOptionsToFormData(item.orderAnswers, index)
                : [
                    {
                      id: 'question-1-option-1',
                      index: 0,
                      value: '',
                      isOther: false,
                      file: {
                        value: null,
                        isError: false,
                        isRequired: false,
                      },
                    },
                    {
                      id: 'question-1-option-2',
                      index: 1,
                      value: '',
                      isOther: false,
                      file: {
                        value: null,
                        isError: false,
                        isRequired: false,
                      },
                    },
                  ],
          isRequired:
            item.questionType._id === '6448afac1dde4cea6034cda0' ||
            item.questionType._id === '6448afcf45a431c8981ff6d5',
        },
        required: {
          ...defaultValue.options,
          value: JSON.parse(item.isRequired),
        },
        allowMultipleSelection: {
          ...defaultValue.options,
          value: item.isMultipleSelection ? item.isMultipleSelection : false,
          isRequired: false,
        },
        otherOption: {
          value:
            item.questionType.name === 'rankOrder'
              ? checkArrayOfObjectHasField(item.orderAnswers, 'isOthers')
              : item.questionType.name === 'multipleChoice'
                ? checkArrayOfObjectHasField(item.answerChoices, 'isOthers')
                : false,
          isError: false,
          isRequired: false,
        },
        pictureChoice: {
          value: item.pictureChoice || false,
          isError: false,
          isRequired: false,
        },
        multipleSelection: {
          ...defaultValue.options,
          value: item.multipleSelection ? item.multipleSelection : '',
          isRequired:
            item.questionType._id === '6448afac1dde4cea6034cda0' &&
            item.isMultipleSelection,
        },
        answerNumbers: {
          ...defaultValue.options,
          value: item.answerNumbers ? item.answerNumbers : '',
          isRequired:
            item.questionType._id === '6448afac1dde4cea6034cda0' &&
            item.isMultipleSelection &&
            item.multipleSelection?.toLowerCase() === 'exact number',
        },
        opinionScale: {
          ...defaultValue.options,
          value: item.opinionScale ? item.opinionScale : '',
          isRequired: item.questionType._id === '6448af76a5f840a74e23814f',
        },
        highScoreLabel: {
          ...defaultValue.options,
          value: item.highScoreLabel ? item.highScoreLabel : '',
          isRequired: item.questionType._id === '6448af76a5f840a74e23814f',
        },
        lowScoreLabel: {
          ...defaultValue.options,
          value: item.lowScoreLabel ? item.lowScoreLabel : '',
          isRequired: item.questionType._id === '6448af76a5f840a74e23814f',
        },
        uploadPhotos: {
          value: item?.questionImageName ?? null,
          isError: false,
          isRequired: false,
        },
      };
    });
    // console.log("🚀 ~ convertedData ~ convertedData:", convertedData);
    return convertedData;
  };
  useEffect(() => {
    const mapOptions = (options: any) => {
      const convetedOptions = options.map(
        (item: { _id: string; value: string }, index: number) => {
          return {
            id: item._id,
            index: index,
            value: item.value,
          };
        }
      );

      return convetedOptions;
    };
    const getStaffData = (staffIds: Array<string>) => {
      return Promise.all(
        staffIds.map((staffId: string) => {
          return getStaffDetail(staffId).then(
            (response) => response.data.userProfile
          );
        })
      ).then((response) => {
        return convertStaffList(response);
      });
    };
    const convertResponseToFormValue = async (surveyData: any) => {
      // console.log(convertResponseToFormValue, "surveyData");
      let appendsFormValue = formValue;
      let appendsLanguage = surveyData.displayLanguage;

      const hvEN = surveyData.displayLanguage.includes('en');
      const hvTC = surveyData.displayLanguage.includes('tc');

      let descriptionEN = '';
      let descriptionTC = '';
      let nameEN = '';
      let nameTC = '';
      if (hvEN) {
        if (hvTC) {
          nameEN = surveyData.surveyTitle.find(
            (item: any) => item.lang === 'en'
          ).value;
          nameTC = surveyData.surveyTitle.find(
            (item: any) => item.lang === 'tc'
          ).value;
          descriptionEN = surveyData.surveyDescription.find(
            (item: any) => item.lang === 'en'
          ).value;
          descriptionTC = surveyData.surveyDescription.find(
            (item: any) => item.lang === 'tc'
          ).value;
        } else {
          nameEN = surveyData.surveyTitle;
          nameTC = '';
          descriptionEN = surveyData.surveyDescription;
          descriptionTC = '';
        }
      } else if (hvTC) {
        nameEN = '';
        nameTC = surveyData.surveyTitle;
        descriptionEN = '';
        descriptionTC = surveyData.surveyDescription;
      }
      appendsFormValue = {
        ...appendsFormValue,
        anonymousResponses: {
          ...appendsFormValue.anonymousResponses,
          value: surveyData.anonymousResponses,
        },
        cover: {
          ...appendsFormValue.cover,

          value: {
            imageUrl: surveyData.coverImageUrl,
            thumbUrl: surveyData.thumbUrl,
          },
        },
        surveyDate: {
          ...appendsFormValue.surveyDate,
          value: {
            startDate: moment.unix(surveyData.surveyStartDate).format(),
            endDate: moment.unix(surveyData.surveyEndDate).format(),
          },
        },
        respondents: {
          ...appendsFormValue.respondents,
          value: respondentsList.find(
            (item: any) =>
              item.id === (surveyData.allowAllPartners ? 'public' : 'private')
          ),
        },
        displayLanguage: {
          ...appendsFormValue.displayLanguage,
          value: surveyData.displayLanguage,
        },
        nameEN: {
          ...appendsFormValue.nameEN,
          value: nameEN,
        },
        nameTC: {
          ...appendsFormValue.nameTC,
          value: nameTC,
        },
        descriptionEN: {
          ...appendsFormValue.descriptionEN,
          value: descriptionEN,
        },
        descriptionTC: {
          ...appendsFormValue.descriptionTC,
          value: descriptionTC,
        },
        invitationScope: {
          ...appendsFormValue.invitationScope,
          value: {
            group: surveyData.respondents.forGroups.map((item: any) => {
              return {
                value: item.storeId,
                label: item.storeName,
                operationValue: item._id,
                districtValue: '',
              };
            }),
            individuals: surveyData.respondents.forPartners.map((item: any) => {
              return {
                id: item.partnerId,
                name: item.userName,
                imageUrl: item.imageUrl,
                avatar: '',
                department: item.departmentName,
                jobTitle: item.positionName,
              };
            }),
          },
        },
        questions: {
          ...appendsFormValue.questions,
          value: convertToFormData(surveyData.questions),
        },
        uploadPhotos: {
          value: null,
          isError: false,
          isRequired: false,
        },
      };

      handleChangeLang(appendsLanguage);
      setInitFormValue(appendsFormValue);
      // setSelectedLang(displayLanguage);
    };
    const convertTemplateToFormValue = (surveyData: any) => {
      // console.log("🚀 ~ convertTemplateToFormValue ~ surveyData:", surveyData);
      let appendsFormValue = formValue;
      let appendsLanguage = surveyData.displayLanguage;

      const hvEN = surveyData.displayLanguage.includes('en');
      const hvTC = surveyData.displayLanguage.includes('tc');

      let descriptionEN = '';
      let descriptionTC = '';
      let nameEN = '';
      let nameTC = '';
      if (hvEN) {
        if (hvTC) {
          nameEN = surveyData.surveyTemplateName.find(
            (item: any) => item.lang === 'en'
          ).value;
          nameTC = surveyData.surveyTemplateName.find(
            (item: any) => item.lang === 'tc'
          ).value;
          descriptionEN = surveyData.surveyDescription.find(
            (item: any) => item.lang === 'en'
          ).value;
          descriptionTC = surveyData.surveyDescription.find(
            (item: any) => item.lang === 'tc'
          ).value;
        } else {
          nameEN = surveyData.surveyTemplateName;
          nameTC = '';
          descriptionEN = surveyData.surveyDescription;
          descriptionTC = '';
        }
      } else if (hvTC) {
        nameEN = '';
        nameTC = surveyData.surveyTemplateName;
        descriptionEN = '';
        descriptionTC = surveyData.surveyDescription;
      }
      appendsFormValue = {
        ...appendsFormValue,

        cover: {
          ...appendsFormValue.cover,
          value: {
            coverImageExtension: surveyData.coverImageExtension,
            coverImageName: surveyData.coverImageName,
            coverImagePath: surveyData.coverImagePath,
            imageUrl: surveyData.coverImageUrl,
            thumbExtension: surveyData.thumbExtension,
            thumbName: surveyData.thumbName,
            thumbPath: surveyData.thumbPath,
            thumbUrl: surveyData.thumbUrl,
          },
        },

        displayLanguage: {
          ...appendsFormValue.displayLanguage,
          value: surveyData.displayLanguage,
        },
        nameEN: {
          ...appendsFormValue.nameEN,
          value: nameEN,
          isRequired: hvEN ? true : false,
        },
        nameTC: {
          ...appendsFormValue.nameTC,
          value: nameTC,
          isRequired: hvTC ? true : false,
        },
        descriptionEN: {
          ...appendsFormValue.descriptionEN,
          value: descriptionEN,
          isRequired: hvEN ? true : false,
        },
        descriptionTC: {
          ...appendsFormValue.descriptionTC,
          value: descriptionTC,
          isRequired: hvTC ? true : false,
        },
        questions: {
          ...appendsFormValue.questions,
          value: convertToFormData(surveyData.questions),
          // value: surveyData.questions,
        },
        uploadPhotos: {
          value: null,
          isError: false,
          isRequired: false,
        },
      };
      handleChangeLang(appendsLanguage);
      setInitFormValue(appendsFormValue);
      // setSelectedLang(displayLanguage);
    };

    // const getDropdownList = async () => {
    //   setTimeout(() => {
    //     const response = {
    //       data: {
    //         respondentsList,
    //         respondentsGroupList,
    //       },
    //     };
    //     setDropdownList(response.data);
    //     console.log("setDropdownList", response.data);
    //   }, 3000);
    // };

    // getDropdownList();

    if (id || props.id) {
      if (props.mode === 'edit') {
        const surveyId = props.id ?? id;
        getSurvey(surveyId as string, { isEdit: true }).then(
          (response: any) => {
            convertResponseToFormValue(response.data.survey);
            setDisableField(
              response.data.survey.status === 'Active' ||
                response.data.survey.status === 'Completed'
                ? true
                : false
            );
            setStatus(response.data.survey.status);
          }
        );
      } else if (props.mode === 'create') {
        const templateId = props.id ?? id;
        getSurveyTemplate(templateId as string).then((response) => {
          convertTemplateToFormValue(response.data.surveyTemplate);
        });
      }
    } else {
      setInitFormValue({
        nameEN: {
          ...formValue.nameEN,
          isRequired: true,
        },
        descriptionEN: {
          ...formValue.descriptionEN,
          isRequired: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, props.id]);

  const handleFileFieldChange = async (
    name: string,
    value: File | File[] | null
  ) => {
    if (!value || Array.isArray(value)) return false;
    // console.log("handleFileFieldChange", name, value);
    try {
      const response = await uploadImage(value);

      if (response.status === 200) {
        setTimeout(() => {
          handleInputFieldChange(name, response.data.surveyCoverPhoto);
        }, 800);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.response.data) {
        setFailMsg({
          open: true,
          message: error.response.data.message,
        });
      }
      throw error;
    }
  };

  const PopupTitle: FC<{ title: any }> = (props) => {
    return (
      <Grid container alignItems="center" className={styles.popupHeader}>
        <Grid item xs>
          <Typography variant="h4">{props.title}</Typography>
        </Grid>
        <Grid item xs="auto">
          <IconButton
            disableRipple
            size="small"
            className={styles.closeButton}
            onClick={() => setOpenDeleteSurveyPopup(false)}
          >
            <img src="/assets/images/close_btn.svg" alt="" />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  function checkSaveButtonDisabled() {
    if (formValue.questions.value.length === 0) {
      return true;
    } else {
      const questionArr = formValue.questions.value;
      for (let i = 0; i < formValue.questions.value.length; i++) {
        if (
          mcAndRankOrderQuestionTypeId.includes(
            questionArr[i].questionType.value
          )
        ) {
          for (let j = 0; j < questionArr[i].options.value.length; j++) {
            if (questionArr[i].options.value[j].value === '') {
              return true;
            }
          }
        }
      }
    }
    if (formValue.cover.value === null) {
      return true;
    }
    if (formValue.displayLanguage.value.includes('en')) {
      if (formValue.nameEN.value === '') {
        return true;
      }
      if (formValue.descriptionEN.value === '') {
        return true;
      }
    }
    if (formValue.displayLanguage.value.includes('tc')) {
      if (formValue.nameTC.value === '') {
        return true;
      }
      if (formValue.descriptionTC.value === '') {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {!openPreviewPopup && (
        <>
          <Header
            title={editMode ? t('survey.title') : t('survey.create.newSurvey')}
            enableCloseButton
            // closeButtonNavigation="/survey"
            closeButtonFunction={props.onBack}
          >
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item xs="auto">
                {isLoading ? (
                  <Link component="button" variant="button" underline="none">
                    Loading...
                  </Link>
                ) : (
                  <Link
                    component="button"
                    variant="button"
                    disabled={!isDirty || checkSaveButtonDisabled()}
                    underline="none"
                    onClick={handleSubmit}
                  >
                    {t('general.save')}
                  </Link>
                )}
              </Grid>
            </Grid>
          </Header>
          <Grid item xs className={styles.eventContainer}>
            <Stack gap={1} className={styles.formContainer}>
              <Box>
                <Box
                  className={`${styles.formField} ${styles.formField__fullWidth}`}
                >
                  <UploadFileField
                    id="cover"
                    name="cover"
                    label={t('survey.create.coverImage')}
                    helperText={t('survey.create.coverImageDimension', {
                      width: 1125,
                      height: 632,
                    })}
                    value={formValue.cover.value}
                    error={formValue.cover.isError}
                    required={formValue.cover.isRequired}
                    accept="image/*"
                    limitSize={5 * 1000 * 1000}
                    onChange={(files) => {
                      handleFileFieldChange('cover', files);
                    }}
                    onRemove={(files) => {
                      handleInputFieldChange('cover', files);
                    }}
                    dimension={{ width: 1125, height: 632 }}
                    disableDimensionChecking
                    enableEdit={true}
                    optionsTitle={t('myProfile.editProfile.editCoverPhoto')}
                    disabled={disableField}
                  />
                </Box>
                <Box className={styles.formWrapper}>
                  {process.env.REACT_APP_LOCATION !== 'PHL' && (
                    <Box className={styles.formField}>
                      <LanguageDropdown
                        value={formValue.displayLanguage.value}
                        disabled={disableField}
                        onChange={handleChangeLang}
                      />
                    </Box>
                  )}

                  {formValue.displayLanguage.value.some(
                    (lang: string) => lang === 'en'
                  ) && (
                    <>
                      <Box className={styles.formField}>
                        <InputField
                          id="nameEN"
                          name="nameEN"
                          placeholder={t('survey.create.surveyTitleEn')}
                          value={formValue.nameEN.value}
                          error={formValue.nameEN.isError}
                          required={formValue.nameEN.isRequired}
                          disabled={disableField}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            handleInputFieldChange(name, value);
                          }}
                        />
                      </Box>
                      <Box className={styles.formField}>
                        <InputField
                          id="descriptionEN"
                          name="descriptionEN"
                          placeholder={t('survey.create.descriptionEn')}
                          value={formValue.descriptionEN.value}
                          error={formValue.descriptionEN.isError}
                          required={formValue.descriptionEN.isRequired}
                          disabled={disableField}
                          multiline
                          helperText={
                            <Typography variant="body2" align="right">
                              {`${formValue.descriptionEN.value.length}/${descriptionLimit}`}
                            </Typography>
                          }
                          onChange={(e) => {
                            const { name, value } = e.target;
                            handleTextareaFieldChange(
                              name,
                              value,
                              descriptionLimit
                            );
                          }}
                        />
                      </Box>
                    </>
                  )}
                  {formValue.displayLanguage.value.some(
                    (lang: string) => lang === 'tc'
                  ) && (
                    <>
                      <Box className={styles.formField}>
                        <InputField
                          id="nameTC"
                          name="nameTC"
                          placeholder={t('survey.create.surveyTitleTc')}
                          value={formValue.nameTC.value}
                          error={formValue.nameTC.isError}
                          required={formValue.nameTC.isRequired}
                          disabled={disableField}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            handleInputFieldChange(name, value);
                          }}
                        />
                      </Box>
                      <Box className={styles.formField}>
                        <InputField
                          id="descriptionTC"
                          name="descriptionTC"
                          placeholder={t('survey.create.descriptionTc')}
                          value={formValue.descriptionTC.value}
                          error={formValue.descriptionTC.isError}
                          required={formValue.descriptionTC.isRequired}
                          disabled={disableField}
                          multiline
                          helperText={
                            <Typography variant="body2" align="right">
                              {`${formValue.descriptionTC.value.length}/${descriptionTcLimit}`}
                            </Typography>
                          }
                          onChange={(e) => {
                            const { name, value } = e.target;
                            handleTextareaFieldChange(
                              name,
                              value,
                              descriptionTcLimit
                            );
                          }}
                        />
                      </Box>
                    </>
                  )}
                  <Box className={styles.formField}>
                    <SelectField
                      id="respondents"
                      name="respondents"
                      label={t('survey.create.respondents')}
                      disabled={disableField}
                      value={formValue.respondents.value}
                      error={formValue.respondents.isError}
                      required={formValue.respondents.isRequired}
                      options={respondentsList}
                      isOptionReverse={true}
                      getOptionValue={(value) => value.id}
                      getOptionLabel={(value) => value.name}
                      getOptionDisplay={(value) => value.displayName}
                      getOptionDesc={(value) => value.description}
                      replaceOptionDesc={optionDesc != null ? optionDesc : ''}
                      onChange={(value) => {
                        handleInputFieldChange('respondents', value);
                      }}
                      handleOptionClick={(value) => {
                        // console.log("handleOptionClick", value);
                        if (value.isNextStep) {
                          setShowAudiencePopup(true);
                        }
                      }}
                    />
                  </Box>
                  <Box className={styles.formField}>
                    <DateTimePickerField
                      id="surveyDate"
                      name="surveyDate"
                      label={t('survey.create.surveyDate')}
                      popupLabel={t('survey.create.surveyDate')}
                      startDate={formValue.surveyDate.value.startDate}
                      endDate={formValue.surveyDate.value.endDate}
                      error={formValue.surveyDate.isError}
                      required={formValue.surveyDate.isRequired}
                      disabled={status === 'Completed'}
                      disableStartDate={status === 'Active'}
                      disableEndDateControl
                      // eslint-disable-next-line
                      onChange={(value: any) => {
                        handleInputFieldChange('surveyDate', value);
                      }}
                    />
                  </Box>
                  <Box className={styles.formToggleField}>
                    <ToggleButton
                      label={t('survey.create.anonymousResponses')}
                      disabled={disableField}
                      checked={formValue.anonymousResponses.value}
                      setChecked={(checked: boolean) => {
                        handleToggleButtonChange('anonymousResponses', checked);
                      }}
                    />
                  </Box>
                  <Box className={styles.formField}>
                    <QuestionField
                      // data={convertToFormData(formValue.questions.value)}
                      data={formValue.questions.value}
                      disabled={disableField}
                      onChange={(value) => {
                        handleInputFieldChange('questions', value);
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <Stack className={styles.formWrapper} direction="row">
                {status === 'Scheduled' && (
                  <Stack
                    className={styles.deleteBtn}
                    direction="row"
                    gap="6px"
                    onClick={() => setOpenDeleteSurveyPopup(true)}
                  >
                    {/* <img src="/assets/images/eye.svg" /> */}
                    <img
                      src="/assets/images/surveyForm_deleteButton.svg"
                      alt=""
                    />
                    <Typography variant="body1">
                      {t('general.delete')}
                    </Typography>
                  </Stack>
                )}
                {status !== 'Completed' && (
                  <Stack
                    className={styles.previewBtn}
                    direction="row"
                    gap="6px"
                    onClick={handlePreview}
                  >
                    {/* <img src="/assets/images/eye.svg" /> */}
                    <img src="/assets/images/eye_active.svg" alt="" />
                    <Typography variant="body1">
                      {t('survey.create.preview')}
                    </Typography>{' '}
                  </Stack>
                )}
              </Stack>
            </Stack>
            <InvitationScopeField
              type="hidden"
              id="invitationScope"
              name="invitationScope"
              label={t('general.chooseAudience')}
              value={formValue.invitationScope.value}
              error={formValue.invitationScope.isError}
              required={formValue.invitationScope.isRequired}
              startAdornment={
                <img src="/assets/images/eventIcon_user_plus.svg" alt="" />
              }
              onChange={(value) => {
                handleInputFieldChange('invitationScope', value);
              }}
              groupOptionsFetch={getInvitationGroupList}
              groupOptionsFetchKey={
                process.env.REACT_APP_LOCATION !== 'TWN'
                  ? 'storeInfo.area'
                  : 'storeInfo.district'
              }
              groupOptionsIdKey="value"
              individualsOptionsFetch={getStaffList}
              individualsOptionsFetchKey="staffList"
              individualsOptionsIdKey="partnerId"
              individualsFilterFetch={getStaffFilterList}
              handleOpen={showAudiencePopup}
              handleHiddenValue={getSubmitData}
              handleHiddenClose={getIsClosed}
            />
          </Grid>
        </>
      )}

      {openPreviewPopup && (
        <SurveyForm
          data={convertFormValueToRequest()}
          coverImage={formValue.cover.value}
          onClose={() => setOpenPreviewPopup(false)}
        ></SurveyForm>
      )}
      {/* {openPreviewPopup && "openPreviewPopup"} */}

      <Snackbar
        open={failMsg.open}
        autoHideDuration={6000}
        onClose={() => setFailMsg((prev) => ({ ...prev, open: false }))}
      >
        <Alert severity="error">{failMsg.message}</Alert>
      </Snackbar>
      <Popup
        isOpen={openDeleteSurveyPopup}
        setIsOpen={(close: boolean) => setOpenDeleteSurveyPopup(close)}
        title={<PopupTitle title={t('survey.create.deleteSurveyTitle')} />}
        content={t('survey.create.deleteSurveyDescription')}
        confirmBtnText={t('general.delete')}
        buttonColor="#C82014"
        onClickCancel={() => {
          setOpenDeleteSurveyPopup(false);
        }}
        onClickConfirm={() => {
          handleDeleteSurvey();
          setOpenDeleteSurveyPopup(false);
        }}
      />
    </>
  );
};

export default SurveyCreate;
