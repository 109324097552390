import React, { useEffect, useState } from 'react';

import styles from './crazy-ad.module.scss';

interface CrazyAdProps {
  defaultCountDown?: number;
  imageSrc: {
    url: string;
    id: string;
  };
}

const CrazyAd: React.FC<CrazyAdProps> = ({
  defaultCountDown = 3,
  imageSrc,
}) => {
  const [countdown, setCountdown] = useState(defaultCountDown);
  const [isSkip, setIsSkip] = useState(false);
  const handleSkip = () => {
    setIsSkip(true);
    localStorage.setItem('CRAZY_AD_READ', 'true');
    localStorage.setItem('CRAZY_AD_Id', imageSrc.id);
  };

  useEffect(() => {
    if (localStorage.getItem('CRAZY_AD_READ') !== 'true') {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      if (countdown === -1) {
        localStorage.setItem('CRAZY_AD_READ', 'true');
        localStorage.setItem('CRAZY_AD_Id', imageSrc.id);
        clearInterval(timer);
      }

      return () => {
        clearInterval(timer);
      };
    }
  }, [countdown]);

  return countdown === -1 || isSkip ? null : (
    <>
      {imageSrc.url ? (
        <div className={styles.crazyAd}>
          <div
            className={styles.crazyAdContainer}
            style={{ backgroundImage: `url("${imageSrc.url}")` }}
          >
            <button
              className={`${styles.text} ${styles.skipBtn}`}
              onClick={handleSkip}
            >
              Skip {countdown}
            </button>
            {/* <img src={imageSrc.url} alt="Crazy Ad" /> */}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CrazyAd;
