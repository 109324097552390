import React, { useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { getStoreAndFinancialList } from '../../services/homepage';
import { isHKStyle } from '../../utility';
import styles from './sections.module.scss';

type Props = {};

const StoreAndFinancialUpdatesSection = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = React.useState<any>({});
  useEffect(() => {
    const getStoreAndFinancialData = async () => {
      try {
        const response = await getStoreAndFinancialList();
        if (response.status === 200 && response.data) {
          // console.log("getStoreAndFinancialList");
          setData({
            lastUpdate: response.data.lastUpdateDate
              ? `${t('homepage.storeLastUpdate')} ${moment
                  .unix(response.data.lastUpdateDate)
                  .format('YYYY-MM-DD')}`
              : null,
            list: [
              {
                title: t('homepage.totalStore'),
                icon: '/assets/images/menu_store.svg',
                value:
                  typeof response.data.totalStore === 'number'
                    ? response.data.totalStore
                    : 0,
              },
              {
                title: t('homepage.MTDSales'),
                icon: '/assets/images/shortcutMenu_ADP.svg',
                value: response.data.MTDSales
                  ? `${response.data.MTDSales}%`
                  : 0,
              },
              {
                title: t('homepage.YTDSales'),
                icon: '/assets/images/shortcutMenu_MPI.svg',
                value: response.data.YTDSales
                  ? `${response.data.YTDSales}%`
                  : 0,
              },
            ],
          });
        }
      } catch (error) {
        // console.log(error);
        throw error;
      }
    };
    getStoreAndFinancialData();
  }, []);

  return (
    <Box className={styles.sectionContainer}>
      <Box
        className={`${styles.sectionWrapper} ${isHKStyle() ? styles.hkStyle : ''}`}
      >
        <Box className={styles.sectionHeader} style={{ marginBottom: '4px' }}>
          <Typography
            className={styles.sectionTitle}
            variant="body1"
            style={{ fontSize: '18px' }}
          >
            {t('homepage.storeAndFinancialUpdates')}
          </Typography>
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => {
              navigate('/store-and-financial-updates');
            }}
          >
            <div
              style={{ fontSize: '12px', color: '#6B6B6B', fontWeight: 600 }}
            >
              {t('general.more')}
            </div>
            <img src="/assets/images/more_button_right.svg" alt="" />
          </div>
        </Box>
        <Typography className={styles.subtext} variant="body1">
          {data.lastUpdate}
        </Typography>
        <Box className={styles.storeAndFinancial}>
          {data?.list?.length > 0 &&
            data.list.map((item: any, index: any) => {
              return (
                <div className={styles.item} key={`storeAndFinancial-${index}`}>
                  {/* <img src={item.icon} alt="" /> */}
                  <Typography className={styles.value} variant="h3">
                    {item.value}
                  </Typography>
                  <Typography className={styles.title} variant="h3">
                    {item.title}
                  </Typography>
                </div>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default StoreAndFinancialUpdatesSection;
