import { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
// import toHtml from "html-react-parser";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DropdownField from '../../../components/dropdown-field';
import Header from '../../../components/header/header';
import InputField from '../../../components/input-field';
import RoundButton from '../../../components/round-button';

import { getStaffFilterList } from '../../../services/common';
import { createGreenBox } from '../../../services/homepage';
import { FormValueType, useFormHook } from '../../../utility/useFormHook';
import styles from './theGreenBox.module.scss';

type Props = {};

const mockData = require('./mockData.json');

const TheGreenBox = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const contentLimit = 2000;
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dropdownList, setDropdownList] = useState<{
    [key: string]: Array<{ _id: string; name: string }>[];
  }>({
    functionList: [],
  });
  const defaultFormValue: FormValueType = {
    function: {
      value: null,
      isError: false,
      isRequired: true,
    },
    content: {
      value: '',
      isError: false,
      isRequired: true,
    },
  };
  const {
    isDirty,
    formValue,
    handleInputFieldChange,
    handleTextareaFieldChange,
    checkValidation,
    setInitFormValue,
  } = useFormHook({
    value: defaultFormValue,
  });

  useEffect(() => {
    const getFunctionList = async () => {
      const response = await getStaffFilterList();
      if (response.status === 200) {
        // console.log("response", response.data.department);
        let temp: any = [];
        response.data.department.forEach((item: any) => {
          temp.push({
            _id: item._id,
            name:
              process.env.REACT_APP_LOCATION === 'TWN'
                ? item.name
                : item.staffFunction,
          });
        });

        setDropdownList({ functionList: temp });
      }
    };
    getFunctionList();
  }, []);

  const checkIsValidate = () => {
    const valid = Object.keys(formValue).map((key) => {
      const field = formValue[key];
      if (field.isError || !field.value) {
        return false;
      } else {
        return true;
      }
    });

    return new Set(valid).has(false);
  };

  const convertFormValueToRequest = () => {
    const getFieldValue = (key: keyof FormValueType) => formValue[key].value;
    return {
      function: getFieldValue('function')._id,
      content: getFieldValue('content'),
    };
  };

  const handleSubmit = async () => {
    const isValid = checkValidation();
    if (isValid && !isLoading) {
      setIsLoading(true);
      const request = convertFormValueToRequest();
      // console.log("request", request);
      const submitTemp = {
        functionId: request.function,
        comment: request.content,
      };
      try {
        const response = await createGreenBox(submitTemp);
        // console.log("handleSubmit", response);
        if (response.status === 200) {
          setIsSubmitted(true);
        }
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
  };
  return !isSubmitted ? (
    <>
      <Header
        title={t('greenBox.title')}
        enableBackButton
        closeButtonFunction={() => {
          navigate(-1);
        }}
      />
      <Grid item xs className={styles.container}>
        <Box className={styles.banner}>
          <img
            src="/assets/images/the_green_box.png"
            alt="theGreenBox"
            className={styles.bannerBg}
          />
          <div className={styles.bannerInfo}>
            <div className={styles.bannerIcon}>
              {t('greenBox.title')}
              {/* <img src="/assets/images/mailbox.svg" alt="mailbox" /> */}
            </div>
            <div className={styles.bannerCaption}>
              {t('greenBox.introduction')}
            </div>
          </div>
        </Box>
        <Box className={styles.content}>
          <div className={styles.formCaption}>{t('greenBox.form.caption')}</div>
          <Box className={styles.formContainer}>
            <Box className={styles.formField}>
              <DropdownField
                id="function"
                name="function"
                label={t('greenBox.form.function')}
                disableLabel
                value={formValue.function.value}
                error={formValue.function.isError}
                required={formValue.function.isRequired}
                options={dropdownList.functionList}
                getOptionValue={(value) => value._id}
                getOptionLabel={(value) => value.name}
                onChange={(value) => {
                  handleInputFieldChange('function', value);
                }}
              />
            </Box>
            <Box className={styles.formField}>
              <InputField
                id="content"
                name="content"
                placeholder={t('greenBox.form.question')}
                value={formValue.content.value}
                error={formValue.content.isError}
                required={formValue.content.isRequired}
                multiline
                helperText={
                  <Grid container flexWrap="nowrap">
                    {formValue.content.isError && (
                      <Grid item xs="auto" flexShrink={1}>
                        <Typography variant="body2" align="left">
                          {t(
                            'myProfile.contactUs.descriptionWordCountOverHelperText'
                          )}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs>
                      <Typography variant="body2" align="right">
                        {`${formValue.content.value.length}/${contentLimit}`}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                onChange={(e) => {
                  const { name, value } = e.target;
                  handleTextareaFieldChange(name, value, contentLimit);
                }}
              />
            </Box>
            <Box className={styles.formAction}>
              <RoundButton disabled={checkIsValidate()} onClick={handleSubmit}>
                {isLoading
                  ? `${t('general.loading')}...`
                  : t('greenBox.form.submit')}
              </RoundButton>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  ) : (
    <Grid item xs className={styles.container}>
      <Box className={styles.doneContainer}>
        <Box className={styles.doneContent}>
          <Box className={styles.doneIcon}>
            <img src="/assets/images/green-box-submitted.svg" alt="done" />
          </Box>
          <Box className={styles.doneCaption}>
            <h2>{t('greenBox.feedback.title')}</h2>
            <p>{t('greenBox.feedback.caption')}</p>
          </Box>
        </Box>
      </Box>
      <Box className={styles.doneAction}>
        <RoundButton href="/the-green-box">
          {t('greenBox.feedback.done')}
        </RoundButton>
      </Box>
    </Grid>
  );
};

export default TheGreenBox;
