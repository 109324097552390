import { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CustomSwiper, EventCardSkeleton } from '../../components';
import GroupItemCard from '../../components/group-item-card';

import { getGroupList } from '../../services/partner-network';
import theme from '../../theme';
import { getApiDataByLang, isHKStyle } from '../../utility';
import styles from './sections.module.scss';

type Props = {};

const PartnerNetworkSection = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Array<any>>([]);

  useEffect(() => {
    setIsLoading(true);
    const getList = async () => {
      try {
        const groupResponse = await getGroupList({ page: 1, pageSize: 6 });
        if (
          groupResponse.status === 200 &&
          groupResponse.data.result.length > 0
        ) {
          const result = groupResponse.data.result;
          setData(
            result.map((item: any) => {
              return {
                id: item._id,
                name: getApiDataByLang(item.groupName),
                imageUrl: item.coverImageUrl,
                members: item.memberList,
                memberCount: item.memberCount,
                totalPosts: item.postCount,
              };
            })
          );
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    };
    getList();
  }, []);

  const getGroupSwiperData = (item: any) => {
    return (
      <GroupItemCard
        data={item}
        onDetailClick={() => {
          navigate(`/partner-network/detail/${item.content.id}`);
        }}
      />
    );
  };

  return (
    <Box className={styles.sectionContainer}>
      <Box
        className={styles.sectionWrapper}
        // style={{ padding: '8px 16px 0px 16px' }}
      >
        <Box className={styles.sectionHeader}>
          <Typography
            className={styles.sectionTitle}
            variant="body1"
            style={{ fontSize: '18px' }}
          >
            {t('partnerNetwork.title')}
          </Typography>
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => navigate('/partner-network')}
          >
            <div
              style={{ fontSize: '12px', color: '#6B6B6B', fontWeight: 600 }}
            >
              {t('general.more')}
            </div>
            <img src="/assets/images/more_button_right.svg" alt="" />
          </div>
        </Box>
        {data.length > 0 && (
          <Box {...(isHKStyle() && { className: styles.partnerNetworkSwiper })}>
            <CustomSwiper
              data={data}
              swiperId={`event-swiper`}
              content={getGroupSwiperData}
              slidesPerView={isHKStyle() ? 1.13 : 1.5}
              slidesPerGroup={1}
              spaceBetween={16}
              isAutoHeight={true}
              style={{ paddingBottom: '0px' }}
              isFreeMode
            />
          </Box>
        )}
        {data.length === 0 && !isLoading && (
          <Box className={styles.noticeContainer}>
            <img src="/assets/images/event_logo.svg" alt="" />
            <Typography
              variant="body2"
              color={theme.vars.palette.black.opacity38}
            >
              No current group Stay tuned
            </Typography>
          </Box>
        )}

        {isLoading && <EventCardSkeleton size="small" />}
      </Box>
    </Box>
  );
};

export default PartnerNetworkSection;
