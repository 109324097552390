import { FC, useEffect, useState } from 'react';

import {
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { BottomNavMenu, NoResults, RoundButton } from '../../components';

import { ObjectKey } from '../../interfaces/common-interface';
import { UserState } from '../../reducers/user-slice';
import { getStaffDetail } from '../../services/common';
import { getProfileLanguages, getProfilePronouns } from '../../services/user';
import { RootState } from '../../store/store';
import {
  convertUserProfile,
  getApiDataByLang,
  getDropdownListOnStore,
} from '../../utility';
import styles from './meMyProfile.module.scss';

interface UserDetailType {
  banner: string | null;
  avatar: string | null;
  name: string;
  nickname: string;
  status: string;
  jobTitle: string;
  function: string;
  area: string;
  district: string;
  store: string;
  email: string;
  contactNo: string;
  pronouns: string;
  spokenLang: string[];
  profileAttribute: ObjectKey[];
}

const MeMyProfile: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [userDetail, setUserDetail] = useState<UserDetailType>({
    banner: null,
    avatar: null,
    name: '',
    nickname: '',
    status: '',
    jobTitle: '',
    function: '',
    area: '',
    district: '',
    store: '',
    email: '',
    contactNo: '',
    pronouns: '',
    spokenLang: [],
    profileAttribute: [],
  });
  const [isUserNotFound, setIsUserNotFound] = useState<boolean>(false);
  const { id } = useParams();
  const isHK = process.env.REACT_APP_LOCATION === 'HK';
  useEffect(() => {
    let active = true;

    const findLanguageName = async (langList: string[] | null) => {
      let list: string[] = [];
      if (!langList || langList.length <= 0) return [];

      try {
        const spokenLanguages = await getDropdownListOnStore(
          'spokenLanguages',
          'profileLanguages',
          getProfileLanguages
        );

        langList.forEach((lang: string) => {
          const existLang = spokenLanguages.find(
            (item: ObjectKey) => item._id === lang
          );
          if (existLang) {
            list.push(getApiDataByLang(existLang.name));
          }
        });

        return list;
      } catch (error) {
        return [];
      }
    };

    const findPronounsValue = async (value: ObjectKey | null) => {
      if (!value) return '';

      const preferredPronouns = await getDropdownListOnStore(
        'preferredPronouns',
        'profilePronouns',
        getProfilePronouns
      );

      let pronounsValue: string = '';
      const userPronouns = value;
      if (userPronouns) {
        if (userPronouns['value' as keyof typeof userPronouns]) {
          const pronouns = preferredPronouns.find(
            (item: ObjectKey) =>
              item._id === userPronouns['value' as keyof typeof userPronouns]
          );
          if (pronouns) {
            pronounsValue = getApiDataByLang(pronouns.name);
          }
        } else {
          if (userPronouns['customValue' as keyof typeof userPronouns]) {
            pronounsValue = userPronouns[
              'customValue' as keyof typeof userPronouns
            ] as string;
          }
        }
      }

      return pronounsValue;
    };

    const getUserDetail = async () => {
      try {
        if (id) {
          const response = await getStaffDetail(id);
          if (response.status === 200) {
            const userData = response.data.userProfile;
            if (active) {
              const convertProfile = convertUserProfile(userData, userDetail);
              const langList = await findLanguageName(convertProfile.languages);
              const pronounsValue = await findPronounsValue(
                convertProfile.pronouns
              );
              setUserDetail((prev) => ({
                ...prev,
                ...convertProfile,
                pronouns: pronounsValue,
                spokenLang: langList,
                avatar: userData.userProfilePictureUrl,
                banner: userData.userCoverPhotoUrl,
              }));
            }
          }
        } else {
          const langList = await findLanguageName(userState.languages);
          const pronounsValue = await findPronounsValue(userState.pronouns);

          setUserDetail((prev) => ({
            ...prev,
            banner: userState.banner,
            avatar: userState.avatar,
            name: userState.name,
            nickname: userState.nickname,
            status: userState.status,
            jobTitle: userState.jobTitle,
            function: userState.function,
            area: userState.area,
            district: userState.district,
            store: userState.store,
            email: userState.email,
            contactNo: userState.contactNo,
            pronouns: pronounsValue,
            spokenLang: langList,
            profileAttribute: userState.profileAttribute,
          }));
        }
      } catch (error) {
        if (id) {
          setIsUserNotFound(true);
          // navigate(-1);
        }
        throw error;
      }
    };

    getUserDetail();
    console.log('updated', userState.nickname);
  }, [userState, id, navigate]);

  const SectionItem = (props: {
    label: string;
    content?: string | Array<string>;
  }) => {
    const { label, content } = props;
    const mode = Array.isArray(content) ? 'tag' : 'content';

    const outputContent = () => {
      const stringElement = (value: string) => (
        <Typography
          variant="body1"
          align={mode === 'tag' ? 'left' : 'right'}
          className={styles.profileSectionItemContent}
        >
          {value}
        </Typography>
      );

      if (!content || content === '' || content.length <= 0)
        return stringElement('-');

      if (Array.isArray(content)) {
        return (
          <Stack
            direction="row"
            flexWrap="wrap"
            className={styles.profileSectionItemTagList}
          >
            {content.map((item, index) => (
              <Chip
                key={index}
                label={item}
                color="primary"
                className={styles.profileSectionItemTag}
              />
            ))}
          </Stack>
        );
      } else {
        return stringElement(content);
      }
    };

    return (
      <Box className={styles.profileSectionItem}>
        <Grid
          container
          className={styles.profileItem}
          direction={mode === 'tag' ? 'column' : 'row'}
          data-mode={mode}
        >
          <Grid item xs="auto">
            <Typography
              variant="body1"
              className={styles.profileSectionItemLabel}
            >
              {label}
            </Typography>
          </Grid>
          <Grid item xs>
            {outputContent()}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const handleBackButton = () => {
    if (isHK) {
      navigate('/me');
    } else {
      navigate(-1);
    }
  };

  return isUserNotFound ? (
    <>
      <IconButton onClick={handleBackButton} sx={{ width: 'max-content' }}>
        <img src="/assets/images/close_btn.svg" alt="" />
      </IconButton>
      <NoResults resultsType="USER" />
    </>
  ) : (
    <Box className={styles.profileContainer} style={{ paddingBottom: '72px' }}>
      <Box
        className={styles.profileBanner}
        {...(isHK && { style: { justifyContent: 'flex-end' } })}
      >
        {userDetail.banner && <img src={userDetail.banner} alt="" />}
        <IconButton
          onClick={handleBackButton}
          {...(isHK && {
            style: { transform: 'rotate(180deg)' },
          })}
        >
          <img src="/assets/images/arrow_left_white.svg" alt="" />
        </IconButton>
      </Box>
      <Grid
        container
        justifyContent="flex-end"
        className={styles.profileActions}
      >
        <Avatar
          alt={userDetail.name}
          src={userDetail.avatar ?? ''}
          className={styles.profileAvatar}
          {...(userDetail.avatar && {
            onClick: () => window.open(`${userDetail.avatar}`, '_blank'),
          })}
        />
        {!id && (
          <RoundButton
            className="reversetype small"
            fullWidth={false}
            onClick={() => navigate('/me/my-profile/edit')}
          >
            <Typography variant="h6">
              {t('myProfile.myProfile.editProfile')}
            </Typography>
          </RoundButton>
        )}
      </Grid>
      <Box className={styles.profileSection}>
        <Box className={styles.profileSectionInfo}>
          <Typography variant="h3">{userDetail.name}</Typography>
          <Typography variant="body1">
            {'@'}
            {userDetail.nickname}
          </Typography>
          <Typography variant="body1" className="status">
            {`“`}
            {userDetail.status}
            {`”`}
          </Typography>
        </Box>
      </Box>
      <Box className={styles.profileSection}>
        <Typography variant="h5" className={styles.profileSectionTitle}>
          {t('myProfile.myProfile.workplace')}
        </Typography>
        <SectionItem
          label={t('myProfile.myProfile.jobTitle')}
          content={userDetail.jobTitle}
        />
        <SectionItem
          label={t('myProfile.myProfile.function')}
          content={userDetail.function}
        />

        {process.env.REACT_APP_LOCATION !== 'HK' && (
          <>
            <SectionItem
              label={t('myProfile.myProfile.district')}
              content={userDetail.district}
            />
            <SectionItem
              label={t('myProfile.myProfile.area')}
              content={userDetail.area}
            />
            <SectionItem
              label={t('myProfile.myProfile.store')}
              content={userDetail.store}
            />
          </>
        )}
      </Box>
      <Box className={styles.profileSection}>
        <Typography variant="h5" className={styles.profileSectionTitle}>
          {t('myProfile.myProfile.personalDetails')}
        </Typography>
        <SectionItem
          label={t('myProfile.myProfile.email')}
          content={userDetail.email}
        />
        {process.env.REACT_APP_LOCATION === 'TWN' && (
          <SectionItem
            label={t('myProfile.myProfile.contactNo')}
            content={userDetail.contactNo}
          />
        )}
        {process.env.REACT_APP_LOCATION === 'TWN' && (
          <SectionItem
            label={t('myProfile.myProfile.preferredPronouns')}
            content={userDetail.pronouns}
          />
        )}
        <SectionItem
          label={t('myProfile.myProfile.spokenLanguages')}
          content={userDetail.spokenLang.join(', ')}
        />
      </Box>
      {userDetail.profileAttribute && (
        <Box className={styles.profileSection}>
          {userDetail.profileAttribute.map((attribute: any, index: number) => (
            <SectionItem
              key={index}
              label={getApiDataByLang(attribute.attributeName)}
              content={attribute.attributes}
            />
          ))}
        </Box>
      )}
      <BottomNavMenu />
    </Box>
  );
};

export default MeMyProfile;
