import React, { FC, useEffect, useState } from 'react';

import { Box, Grid, Skeleton, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { NoResults } from '../../components';
import Header from '../../components/header/header';

import { GreenApronCardDetailProps } from '../../interfaces/green-apron-card-interface';
import { getReceivedCardsList } from '../../services/green-apron-card';
import { convertDateTime } from '../../utility';
import styles from './greenApronCard.module.scss';

const GreenApronCardReceived: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cardList, setCardList] = useState<Array<GreenApronCardDetailProps>>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEnded, setIsEnded] = useState<boolean>(false);
  const [apiPagination, setApiPagination] = React.useReducer(
    (prev: PaginationType, next: Partial<PaginationType>) => {
      return { ...prev, ...next };
    },
    {
      page: 1,
      pageSize: 10,
    }
  );

  useEffect(() => {
    const getList = async () => {
      try {
        const response = await getReceivedCardsList({
          page: apiPagination.page,
          pageSize: apiPagination.pageSize,
        });
        if (response.status === 200) {
          if (response.data.isEnded) {
            setIsEnded(true);
          }
          const list = response.data.receivedCards.filter(
            (item: any) => item.status === 'RECEIVED'
          );

          if (list && list.length > 0) {
            setCardList(
              // eslint-disable-next-line
              list.map((item: any) => ({
                id: item._id,
                receiver: {
                  id: item.receiverId,
                  name: item.receiver,
                  avatar: '',
                },
                sender: {
                  id: item.senderId,
                  name: item.sender,
                  avatar: '',
                },
                description: item.senderMessage ?? '',
                sendDate: item.sendTime,
                cardInfo: {
                  id: '',
                  name: '',
                  description: '',
                  url: item?.thumbUrl
                    ?.replace('_thumb.png', '.png')
                    ?.replace('_thumb.jpeg', '.jpeg'),
                },
                isReplied: item.cardReplyMessage,
                isRead: item.isRead,
                status: item.status,
              }))
            );
          }
        }
        setApiPagination({ page: apiPagination.page + 1 });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    };

    getList();
  }, []);

  async function fetchMoreData() {
    try {
      const response = await getReceivedCardsList({
        page: apiPagination.page,
        pageSize: apiPagination.pageSize,
      });
      // console.log("response", response);
      let tempCardList = response.data.receivedCards.map((item: any) => ({
        id: item._id,
        receiver: {
          id: item.receiverId,
          name: item.receiver,
          avatar: '',
        },
        sender: {
          id: item.senderId,
          name: item.sender,
          avatar: '',
        },
        description: item.senderMessage ?? '',
        sendDate: item.sendTime,
        cardInfo: {
          id: '',
          name: '',
          description: '',
          url: item?.thumbUrl
            ?.replace('_thumb.png', '.png')
            ?.replace('_thumb.jpeg', '.jpeg'),
        },
        isReplied: item.cardReplyMessage,
        isRead: item.isRead,
        status: item.status,
      }));
      setCardList((prev) => [...prev, ...tempCardList]);
      setApiPagination({ page: apiPagination.page + 1 });
      if (response.data.isEnded) {
        setIsEnded(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const ListItem = (props: { card: GreenApronCardDetailProps }) => {
    const { card } = props;
    const sendDate = convertDateTime(moment.unix(card.sendDate).format());

    return (
      <Box
        id={`card-${card.id}`}
        className={styles.receivedItem}
        onClick={() => navigate(`/green-apron-card/${card.id}/reply`)}
      >
        <Box
          className={`${styles.receivedItemImage} ${
            card.isRead && styles.active
          }`}
        >
          <img
            src={
              card.isRead ? card.cardInfo.url : '/assets/images/cardCover.png'
            }
            alt={card.cardInfo.name}
          />
        </Box>
        <Box className={styles.receivedItemInfo}>
          <Typography variant="body2">{t('greenApronCard.from')}</Typography>
          <Typography variant="subtitle1">{card.sender.name}</Typography>
          <Typography variant="body2">{sendDate}</Typography>
        </Box>
      </Box>
    );
  };

  const LoadingSkeleton = () => {
    return (
      <Box className={styles.receivedItem}>
        <Box className={styles.receivedItemImage}>
          <Skeleton variant="rounded" width="100%" height="100%" />
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Header
        enableBackButton
        disableBottomBorder
        title={t('greenApronCard.cardReceived')}
      />
      <Grid
        item
        xs
        className={styles.sentAndReceivedContainer}
        id="scrollableDiv"
      >
        {!isLoading && cardList.length === 0 ? (
          <NoResults />
        ) : (
          <Grid container className={styles.receivedList}>
            {isLoading &&
              Array.from(Array(4), (_, index) => (
                <Grid key={index} item xs={6}>
                  <LoadingSkeleton />
                </Grid>
              ))}
            <InfiniteScroll
              dataLength={cardList.length}
              next={fetchMoreData}
              hasMore={!isEnded}
              loader={<LoadingSkeleton />}
              scrollableTarget="scrollableDiv"
            >
              {cardList.map(
                (card: GreenApronCardDetailProps, index: number) => (
                  <Grid key={index} item xs={6}>
                    <ListItem card={card} />
                  </Grid>
                )
              )}
            </InfiniteScroll>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default GreenApronCardReceived;
