import React from 'react';

import { Grid, IconButton, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BottomNavMenu, Popup } from '../../components';
import Header from '../../components/header/header';
import InfiniteScrollContainer from '../../components/infinite-scroll-container/InfiniteScrollContainer';

import { getAvailableCouponList } from '../../services/coupon';
import { getApiDataByLang } from '../../utility';
import ReceiveCoupon from '../receiveCoupon/receiveCoupon';
import CouponItem from './couponItem';
import { CouponItemProps } from './interface';
import styles from './myCoupon.module.scss';
import TransferCouponPopup from './transferCouponPopup';

type Props = {};

const MyCoupon = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = React.useState<CouponItemProps[]>([]);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [isDetailOpen, setDetailOpen] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<number>(0);
  const [initialSlide, setInitialSlide] = React.useState<number | null>(0);
  const [transferPopupOpen, setTransferPopupOpen] =
    React.useState<boolean>(false);
  const convertData = (response: any, isNext: boolean) => {
    // console.log('convertData', response);

    setTotalCount(response.totalCount ?? 0);
    const data = response.availableCouponList;
    if (data) {
      const temp = data.map((item: any) => {
        return {
          id: item._id,
          denomination: item.denomination,
          name: item.couponName ? getApiDataByLang(item.couponName) : '-',
          code: item.code,
          expiryDate: item.end_date,
          logo: item.logoImage,
          background: item.backgroundImage,
          backgroundColor: item.cardBackgroundColour,
          primaryColor: item.primaryTextColour,
          secondaryColor: item.secondaryTextColour,
          status: 'Available',
          terms: item.termsAndCondition
            ? getApiDataByLang(item.termsAndCondition)
            : '-',
        };
      });
      if (!isNext) {
        setData(temp);
      } else {
        setData((prev: any) => [...prev, ...temp]);
      }
      // setSelected([{ id: data[0]._id, name: data[0].couponName }]);
    }
  };
  return (
    <>
      <Header
        title={t('coupon.title')}
        enableBackButton
        closeButtonFunction={() => navigate('/home')}
      >
        <div className={styles.tools}>
          <button
            onClick={() => {
              navigate('transfer-coupons');
            }}
          >
            <img src="/assets/images/transfer.svg" alt="coupon history" />
            {t('coupon.transfer')}
          </button>
          <button
            onClick={() => {
              navigate('history');
            }}
          >
            <img src="/assets/images/history_icon.svg" alt="coupon history" />
            <label>{t('coupon.history')}</label>
          </button>
        </div>
      </Header>
      <Grid
        item
        className={styles.container}
        {...(data.length === 0 && {
          style: {
            backgroundColor: 'transparent',
            height: 'calc(100% - 51px)',
          },
        })}
      >
        <div
          className={styles.availableText}
        >{`${t('coupon.available')} (${totalCount})`}</div>
        <InfiniteScrollContainer
          fetchData={getAvailableCouponList}
          returnData={convertData}
          list={data}
          pageSize={999}
          loadingSpinner={
            <Skeleton
              variant="rounded"
              height={146}
              style={{ marginBottom: '16px' }}
            />
          }
        >
          <Grid
            container
            className={styles.couponContainer}
            style={{ paddingBottom: '70px' }}
          >
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <CouponItem
                    {...item}
                    key={`coupon-${item.id}`}
                    showBackground={false}
                    onItemClick={(item) => {
                      // navigate(`detail/${item.id}`);
                      setDetailOpen(true);
                      setInitialSlide(index);
                    }}
                  />
                );
              })}
          </Grid>
        </InfiniteScrollContainer>
      </Grid>
      <BottomNavMenu />
      {data && (
        <Popup
          id={styles.detailPopup}
          isOpen={isDetailOpen}
          setIsOpen={(close: boolean) => {
            setDetailOpen(close);
          }}
          content={
            <>
              <IconButton
                sx={{
                  padding: '0px',
                  position: 'absolute',
                  left: '16px',
                  top: '10px',
                  zIndex: 10,
                }}
                onClick={() => {
                  setInitialSlide(null);
                  setDetailOpen(false);
                }}
              >
                <img
                  src="/assets/images/arrow_left_white.svg"
                  alt="detail back"
                  style={{ width: '28px' }}
                />
              </IconButton>
              <button
                onClick={() => setTransferPopupOpen(true)}
                style={{
                  backgroundColor: 'transparent',
                  fontSize: '14px',
                  color: '#fff',
                  border: 'none',
                  padding: '1px 10px',
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '16px',
                  top: '10px',
                  zIndex: 10,
                }}
              >
                {t('coupon.transfer')}
              </button>
              <ReceiveCoupon
                availableCouponList={data}
                initialSlide={initialSlide}
                getActiveIndex={(index) => {
                  setSelected(index);
                }}
                totalPage={totalCount}
              />
            </>
          }
          disableActions={true}
          fullScreen={true}
        />
      )}
      <TransferCouponPopup
        title={data[selected]?.name}
        selected={[data[selected]?.id]}
        transferPopupOpen={transferPopupOpen}
        setTransferPopupOpen={setTransferPopupOpen}
      />
    </>
  );
};

export default MyCoupon;
