import React, { useEffect, useRef, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  Slide,
  Stack,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import toHtml from 'html-react-parser';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  ActionItem,
  EventResultCard,
  MediaPopup,
  NoticeBox,
  PollButton,
  Popup,
  PostActionButton,
  RoundButton,
} from '../../components';

import { SocialWallCardProps } from '../../interfaces/social-wall-interface';
import { UserState } from '../../reducers/user-slice';
import {
  deletePost,
  deleteReportedPost,
  getReportedByList,
  pinPost,
  pinUntilPost,
  reportPost,
  restoreReportedPost,
  unpinPost,
  updateReportedPost,
} from '../../services/social-wall';
import { RootState } from '../../store/store';
import theme from '../../theme';
import {
  convertDate,
  convertDateTime,
  convertTime,
  convertUrlsToLinks,
  getApiDataByLang,
} from '../../utility';
import Calendar from '../calendar/calendar';
import SelectField from '../select-field';
import styles from './social-wall-card.module.scss';

const currentDomain = window.location.origin;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const PinCalendar: React.FC<{
  date: string;
  onChange: (date: string) => void;
}> = (props) => {
  const [date, setDate] = useState(props.date);

  const handleDateChange = (value: Moment) => {
    const newDateString = value.format();
    setDate(newDateString);
    props.onChange(newDateString);
  };

  return (
    <Calendar
      value={date}
      disablePast
      // isLoading={isLoading}
      onSelectDate={handleDateChange}
    />
  );
};

const ReportField: React.FC<{
  callback?: (value: any) => void;
}> = (props) => {
  const { t } = useTranslation();

  const reportList = [
    {
      id: 'offensive',
      name: t('socialWall.reportedPost.offensive'),
      description: t('socialWall.reportedPost.offensiveDesc'),
    },
    {
      id: 'nudityOrPornography',
      name: t('socialWall.reportedPost.nudityOrPornography'),
      description: t('socialWall.reportedPost.nudityOrPornographyDesc'),
    },
    {
      id: 'harassmentOrBullying',
      name: t('socialWall.reportedPost.harassmentOrBullying'),
      description: t('socialWall.reportedPost.harassmentOrBullyingDesc'),
    },
    {
      id: 'hateSpeechOrSymbols',
      name: t('socialWall.reportedPost.hateSpeechOrSymbols'),
      description: t('socialWall.reportedPost.hateSpeechOrSymbolsDesc'),
    },
    {
      id: 'others',
      name: t('socialWall.reportedPost.others'),
      description: t('socialWall.reportedPost.othersDesc'),
    },
  ];

  const [selected, setSelected] = useState(reportList[0]);
  const [otherReportValue, setOtherReportValue] = useState('');
  const [isOverLength, setIsOverLength] = useState(false);
  const handleSelectedReportPort = (value: any) => {
    setSelected(value);
    props.callback &&
      props.callback({ ...value, ReportDetails: otherReportValue });
  };

  const handleInputOtherReport = (value: string) => {
    setOtherReportValue(value);
    setIsOverLength(value.length > 200);
  };

  return (
    <SelectField
      id="reportPost"
      name="reportPost"
      label={t('socialWall.reportLabel')}
      value={selected}
      options={reportList}
      confirmLabel={t('general.report')}
      getOptionValue={(value) => value.id}
      getOptionLabel={(value) => value.name}
      getOptionDesc={(value) => value.description}
      getOptionDisplay={(value) => {
        return t('socialWall.reportLabel');
      }}
      onChange={(value) => {
        handleSelectedReportPort(value);
      }}
      type="selector"
      disableLabel={true}
      startAdornment={
        <img src="/assets/images/postActionIcon_report.svg" alt="" />
      }
      otherFieldValue="others"
      otherFieldProps={{
        id: 'otherReport',
        name: 'otherReport',
        placeholder: t('socialWall.reportedPost.additionalDetails'),
        value: otherReportValue,
        error: isOverLength,
        required: true,
        onChange: (e) => {
          const { value } = e.target;
          handleInputOtherReport(value as string);
        },
        multiline: true,
        helperText: (
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Typography variant="body2" align="left">
              {isOverLength && t('event.descriptionWordCountOverHelperText')}
            </Typography>
            <Typography variant="body2" align="right">
              {`${otherReportValue.length}/200`}
            </Typography>
          </Stack>
        ),
      }}
    />
  );
};

const RemarkContent = (props: { postData: (data: string) => void }) => {
  const suspendRemarkRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();
  const [textCount, setTextCount] = useState(0);

  const handleKeyUp = () => {
    if (suspendRemarkRef.current) {
      // console.log("handleKeyUp", suspendRemarkRef.current.value);
      setTextCount(suspendRemarkRef.current.value.length);
      props.postData(suspendRemarkRef.current.value);
    }
  };
  return (
    <Box className={`${styles.popupContent}`}>
      <label>{t('socialWall.reportedPost.internalRemarks')}</label>
      <div className="formControl">
        <TextareaAutosize
          placeholder={t('socialWall.reportedPost.remarksHere')}
          ref={suspendRemarkRef}
          onKeyUp={handleKeyUp}
        />
        <span className={`textCount${textCount > 100 ? ' textOver' : ''}`}>
          {textCount}/100
        </span>
      </div>
    </Box>
  );
};

interface ReportDetailType {
  _id: string;
  nickname: string;
  createByName: any;
  createTime: string;
  reportType: string;
  ReportDetails: string;
  userProfilePictureUrl: string;
}

interface SocialWallCardPropsExtend extends SocialWallCardProps {
  handleSnackOpen?: (isOpen: boolean) => void;
  onClickMoreAction?: () => void;
  handleActionBtnClick?: () => void;
  partnerNetworkGroupId?: string;
  partnerNetworkGroupName?: string;
  isPictureChoice?: boolean;
  passIsLiked?: (isLiked: boolean) => void;
  likeBtnCallback?: (value: boolean) => void;
}

export default function SocialWallCard(props: SocialWallCardPropsExtend) {
  const {
    id,
    type,
    isPin,
    likesNo,
    isLike,
    isPostAnonymously,
    commentsNo,
    content,
    annotatedUsers,
    link,
    attachments,
    event,
    pollInfo,
    votingStat,
    voteOption,
    createByName,
    isPersonalAdmin,
    createBy,
    reportBy,
    reportUserList,
    createTime,
    lastUpdateTime,
    expiryDateTime,
    isSchedulePost,
    scheduleStartTime,
    size,
    actionBtnType,
    fixedBtn,
    status,
    reportStatus,
    pinUntil,
    disableLike,
    disableComment,
    handleActionBtnClick,
    isPictureChoice = false,
    passIsLiked,
    likeBtnCallback,
    // handleGetReportDetail,
  } = props;
  // if (id === "65cc8a88b771db6b69decf36") console.log("socialWallCard", props);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const [disablePopupBtn, setDisablePopupBtn] = useState<boolean>(false);

  const [isLiked, setIsLiked] = useState<boolean>(isLike);
  const [popupContent, setPopupContent] = useState<React.ReactNode>();
  const [popupBtnText, setPopupBtnText] = useState<string>();
  const [popupType, setPopupType] = useState<string>();
  const [popupTitle, setPopupTitle] = useState<React.ReactNode>();
  const [showMediaPopup, setShowMediaPopup] = useState<boolean>(false);
  const [showMediaPopupIndex, setShowMediaPopupIndex] = useState<number>(0);
  const [showMediaText, setShowMediaText] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const nowDate = moment();
  const roundTime = 30 - (nowDate.minute() % 30);
  const roundedStartDate = nowDate.add(roundTime, 'minutes').format();
  const roundedEndDate = moment(roundedStartDate).add(1, 'hour').format();
  const isHK = process.env.REACT_APP_LOCATION === 'HK';
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const initLocation = window.location.href.replace(window.location.origin, '');
  const pinList = [
    {
      id: 'noPin',
      name: t('socialWall.create.doNotPinOnTop'),
      displayName: t('socialWall.pin'),
      disableDateTime: true,
      description: '',
    },
    {
      id: 'alwaysPin',
      name: t('socialWall.create.pinForever'),
      displayName: t('socialWall.create.pinForever'),
      disableDateTime: true,
      description: t('socialWall.create.pinForeverDesc'),
    },
    {
      id: 'pinUntil',
      name: t('socialWall.create.pinUntil'),
      displayName: t('socialWall.create.pinUntil'),
      disableDateTime: false,
      description: t('socialWall.create.pinUntilDesc'),
    },
  ];

  let selectedPin: any = pinList[0];
  if (isPin) {
    selectedPin = pinUntil ? pinList[2] : pinList[1];
  }
  let selectedPinDate = pinUntil
    ? moment.unix(pinUntil).format()
    : moment().format();
  let otherReportIsError = false;
  let otherReportValue = '';

  const [formValue, setFormValue] = useState({
    pin: {
      value: pinList[0],
      isError: false,
      isRequired: true,
    },
    pinDate: {
      value: '',
      isError: false,
      isRequired: false,
    },
    report: {
      value: pinList[0],
      isError: false,
      isRequired: true,
    },
    otherReport: {
      value: '',
      isError: false,
      isRequired: false,
    },
    postDate: {
      value: {
        value: null,
        startDate: roundedStartDate,
        endDate: roundedEndDate,
      },
      isError: false,
      isRequired: true,
    },
  });

  const [reportOtherContent, setReportOtherContent] = useState('');
  const [isReportDetailOpen, setReportDetailOpen] = useState(false);
  const [getReportDetailData, setReportDetailData] = useState<
    ReportDetailType[]
  >(null!);

  useEffect(() => {
    setIsLiked(isLike);
  }, [isLike]);

  useEffect(() => {
    // console.log("isLiked", isLiked);
    passIsLiked && passIsLiked(isLiked);
  }, [isLiked]);

  const handleTag = (content: any) => {
    const tag = content.match(
      /(#)([\u4e00-\u9fa5_a-zA-Z0-9]+)|(@@[[)([\u4e00-\u9fa5_a-zA-Z0-9\s]+)(]]&&[[)([\u4e00-\u9fa5_a-zA-Z0-9\s]+)(]]@@)/g
    );
    var remaining = content;
    if (tag) {
      const repl = tag.map((word: any, tagIndex: number) => {
        // console.log("handleTag progress", [content, remaining]);
        let index = remaining.indexOf(word);
        let before = remaining.slice(0, index);
        let after = remaining.slice(index + word.length, remaining.length);
        remaining = after;

        return (
          <React.Fragment key={tagIndex}>
            {toHtml(before)}
            <span
              className={styles.hashtag}
              onClick={() => {
                if (word.charAt(0) === '#') {
                  navigate(`/social-wall/search/${word.slice(1, word.length)}`);
                } else if (word.charAt(0) === '@') {
                  navigate(
                    `/discover-people/profile/${word
                      .split('&&')[1]
                      .replace('[[', '')
                      .replace(']]', '')
                      .replace('@@', '')}`
                  );
                }
              }}
            >
              {word.charAt(0) === '#'
                ? word
                : word
                    .split('&&')[0]
                    .replace('[[', '')
                    .replace(']]', '')
                    .replace('@@', '')}
            </span>
            {tagIndex === tag.length - 1 ? after : ''}
          </React.Fragment>
        );
      });

      return repl;
    }
    return toHtml(content);
  };

  const getReportDetail = (id: string) => {
    // call api use id??
    getReportedByList(id).then((res) => {
      setReportDetailData(res.data.postReportedInfo);
      setReportDetailOpen(true);
    });
  };

  const ReportDetailCard = (props: { data: ReportDetailType }) => {
    const { data } = props;
    return (
      <ListItem key={data._id} disablePadding>
        <div className="reportDetailCard">
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            className="cardTop"
          >
            <Avatar
              alt={data.createByName?.userName}
              src={
                isPostAnonymously
                  ? '/assets/images/profileIcon_admin.png'
                  : data.userProfilePictureUrl + '?cache=true'
              }
            />
            <span>
              {isPostAnonymously
                ? t('general.starbucksAdmin')
                : (data.createByName?.userName ?? data.createByName?.name)}
            </span>
          </Stack>
          <Grid
            container
            className="cardDetails"
            justifyContent="space-between"
          >
            <Grid item xs={4} className="cardTitle">
              {t(`socialWall.reportedPost.reportTime`)}
            </Grid>
            <Grid item xs={8} className="cardContent">
              {convertDateTime(data.createTime)}
            </Grid>
            <Grid item xs={4} className="cardTitle">
              {t('socialWall.reportedPost.reason')}
            </Grid>
            <Grid item xs={8} className="cardContent">
              {t(`socialWall.reportedPost.${data.reportType}`)}
              {data.ReportDetails ? `: ${data.ReportDetails}` : ''}
            </Grid>
          </Grid>
        </div>
      </ListItem>
    );
  };

  const handleClosePopup = () => {
    setReportDetailOpen(false);
  };
  const CloseButton = (props: { onClick: () => void }) => {
    return (
      <IconButton
        id="closeSelectPopupButton"
        className={styles.closeSelectPopupButton}
        disableRipple
        onClick={props.onClick}
      >
        <img src="/assets/images/close_btn.svg" alt="" />
      </IconButton>
    );
  };
  const handleSelectedPin = (value: any) => {
    // console.log("handleSelectedPin", value);
    // console.log("props", props);
    if (
      props.isSchedulePost &&
      props.scheduleStartTime &&
      props.scheduleEndTime
    ) {
      const scheduleStartTime = moment.unix(props.scheduleStartTime).format();
      const scheduleEndTime = moment.unix(props.scheduleEndTime).format();
      const isOutOfRange =
        moment(selectedPinDate).isBefore(scheduleStartTime, 'day') ||
        moment(selectedPinDate).isAfter(scheduleEndTime, 'day');

      if (isOutOfRange || value.id === 'alwaysPin') {
        sessionStorage.setItem(
          'snackbar',
          JSON.stringify({
            type: 'error',
            message: t('socialWall.create.pinError'),
          })
        );
        props.handleSnackOpen && props.handleSnackOpen(true);
      } else if (value.id === 'noPin') {
        unpinPost(id)
          .then(() => navigate(0))
          .catch((error) => console.error('Error pinPost', error));
      } else {
        pinUntilPost(
          id,
          moment(selectedPinDate).format('YYYY-MM-DD') + 'T00:00:00Z'
        )
          .then(() => navigate(0))
          .catch((error) => console.error('Error pinPost', error));
      }
    } else {
      if (value.id === 'pinUntil') {
        pinUntilPost(
          id,
          moment(selectedPinDate).format('YYYY-MM-DD') + 'T00:00:00Z'
        )
          .then(() => navigate(0))
          .catch((error) => console.error('Error pinPost', error));
      } else if (value.id === 'alwaysPin') {
        pinPost(id)
          .then(() => navigate(0))
          .catch((error) => console.error('Error pinPost', error));
      } else if (value.id === 'noPin') {
        unpinPost(id)
          .then(() => navigate(0))
          .catch((error) => console.error('Error pinPost', error));
      }
    }

    setShowPopup(false);
  };

  const handleGoToPage = () => {
    navigate(`/social-wall/view-poll/${id}`);
  };

  const handleResolve = () => {
    // call resolve api
    sessionStorage.setItem(
      'snackbar',
      JSON.stringify({ isShow: true, type: 'resolve' })
    );
    window.location.reload();
  };
  const handleKeep = () => {
    // call resolve api
    updateReportedPost(id as string, 'keep')
      .then((res) => {
        sessionStorage.setItem(
          'snackbar',
          JSON.stringify({ isShow: true, type: 'keep' })
        );
        if (window.location.pathname.endsWith('/social-wall/reported-post')) {
          window.location.reload();
        } else {
          navigate(`/social-wall/reported-post`);
        }
      })
      .catch((err) => {});
  };

  const handleRestore = () => {
    // call resolve api
    restoreReportedPost(id as string)
      .then((res) => {
        sessionStorage.setItem(
          'snackbar',
          JSON.stringify({ isShow: true, type: 'restore' })
        );
        window.location.reload();
      })
      .catch((err) => {});
  };

  const actionItemData = [
    {
      image: '/assets/images/postActionIcon_pin.svg',
      action: 'handlePinPost',
      text: 'socialWall.pin',
    },
    {
      image: '/assets/images/postActionIcon_edit.svg',
      action: 'handleEditPost',
      text: 'socialWall.edit',
    },
    {
      image: '/assets/images/postActionIcon_share.svg',
      action: 'handleSharePost',
      text: 'socialWall.share',
    },
    {
      image: '/assets/images/postActionIcon_report.svg',
      action: 'handleReportPost',
      text: 'socialWall.report',
    },
    {
      image: '/assets/images/postActionIcon_delete.svg',
      action: 'handleDeletePost',
      text: 'general.delete',
    },
  ];
  // console.log("userState", userState?.permission?.SocialWallAdmin);
  // console.log("createBy", createBy);
  const actionItem = (
    <Box className={styles.detailsContainer}>
      {/* <ActionItemList data={actionItemData} /> */}
      <List className={styles.listWrapper}>
        {actionItemData.map((item, index) => {
          switch (item.action) {
            case 'handlePinPost':
              return (
                userState?.permission?.SocialWallAdmin &&
                userState.partnerId === createBy && (
                  <ListItem key={index} sx={{ padding: '0px' }}>
                    <SelectField
                      id="pin"
                      name="pin"
                      label={t('socialWall.create.pinLabel')}
                      value={selectedPin}
                      options={pinList}
                      getOptionValue={(value) => value.id}
                      getOptionLabel={(value) => value.name}
                      getOptionDisplay={(value) => {
                        if (value.id === 'noPin') return t('socialWall.pin');
                        else return t('socialWall.editPin');
                      }}
                      getOptionDesc={(value) => value.description}
                      onChange={(value) => {
                        handleSelectedPin(value);
                      }}
                      type="selector"
                      disableLabel={true}
                      startAdornment={
                        <img
                          src={
                            isPin
                              ? '/assets/images/postActionIcon_pin_full.svg'
                              : item.image
                          }
                          alt=""
                        />
                      }
                      otherFieldValue="pinUntil"
                      otherFieldComponent={() => (
                        <PinCalendar
                          date={selectedPinDate}
                          onChange={(value: string) =>
                            (selectedPinDate = value)
                          }
                        />
                      )}
                    />
                  </ListItem>
                )
              );
            case 'handleReportPost':
              return (
                <ListItem key={index} sx={{ padding: '0px' }}>
                  <ReportField
                    callback={(value: any) => {
                      setShowPopup(false);
                      // call post report api
                      reportPost(id, {
                        reportType: value.id,
                        ReportDetails:
                          value.id === 'others' ? value.ReportDetails : '',
                      })
                        .then((result) => {
                          localStorage.setItem('is-post-reported', 'true');
                          navigate(0);
                        })
                        .catch((err) => {});
                    }}
                  />
                </ListItem>
              );
            case 'handleEditPost':
              return (
                userState.partnerId === createBy && (
                  <ListItem key={index} sx={{ padding: '0px' }}>
                    <ActionItem
                      key={index}
                      image={item.image}
                      text={item.text}
                      onClick={() => {
                        setShowPopup(false);
                        setTimeout(() => {
                          navigate(`/social-wall/edit/${id}`);
                        }, 100);
                      }}
                    />
                  </ListItem>
                )
              );
            case 'handleDeletePost':
              return (
                userState.partnerId === createBy && (
                  <ListItem key={index} sx={{ padding: '0px' }}>
                    <ActionItem
                      key={index}
                      image={item.image}
                      text={item.text}
                      onClick={() => {
                        setShowPopup(false);
                        setTimeout(() => {
                          handleDeletePost();
                        }, 100);
                      }}
                    />
                  </ListItem>
                )
              );
            default:
              return (
                <ListItem key={index} sx={{ padding: '0px' }}>
                  <ActionItem
                    key={index}
                    image={item.image}
                    text={item.text}
                    onClick={() => {
                      setShowPopup(false);
                      setTimeout(() => {
                        if (item.action === 'handlePinPost') {
                          // console.log("handlePinPost", item.action);
                        } else if (item.action === 'handleSharePost') {
                          console.log(
                            'Share: ',
                            `${currentDomain}/social-wall/post-detail/${id}`
                          );
                          if (navigator.share) {
                            navigator
                              .share({
                                title: 'Starbucks Partner app',
                                url: `${currentDomain}/social-wall/post-detail/${id}`,
                              })
                              .then(() => console.log('Successful share'))
                              .catch((error) =>
                                console.error('Error sharing', error)
                              );
                          }
                        }
                      }, 100);
                    }}
                  />
                </ListItem>
              );
          }
        })}
      </List>
    </Box>
  );
  const openMoreActionPopupTitle = (
    <Box className={styles.popupTitle}>
      <span>{t('socialWall.more')}</span>
      <img
        src="/assets/images/close_btn.svg"
        onClick={() => {
          setShowPopup(false);
        }}
      />
    </Box>
  );
  const openMoreActionPopup = () => {
    setShowPopup(true);
    setPopupContent(actionItem);
    setPopupTitle(openMoreActionPopupTitle);
    setDisablePopupBtn(true);
    props.onClickMoreAction && props.onClickMoreAction();
  };

  const handleDeletePost = () => {
    setShowPopup(true);
    setPopupBtnText(t('general.delete'));
    setPopupContent(t('socialWall.delete.confirmMsg'));
    setPopupTitle(t('socialWall.delete.title'));
    setPopupType('scheduled-delete');
    setDisablePopupBtn(false);
  };

  const PopupHeader = (props: { title: string; content: string }) => {
    return (
      <Box className={`${styles.popupTitleBlock}`}>
        <span>{props.title}</span>
        <div className="desc">{props.content}</div>
      </Box>
    );
  };
  const [remarkData, setRemarkData] = useState<string>('');

  const getRemarkData = (data: string) => {
    setRemarkData(data);
  };
  const handleSuspend = () => {
    setShowPopup(true);
    setPopupBtnText(t('socialWall.reportedPost.suspend'));
    setPopupContent(<RemarkContent postData={getRemarkData} />);
    setPopupType('suspend');
    setPopupTitle(
      <PopupHeader
        title={t('socialWall.reportedPost.suspendPost')}
        content={t('socialWall.reportedPost.suspendPostDesc')}
      />
    );
    setDisablePopupBtn(false);
  };
  const handleReportedDelete = () => {
    setShowPopup(true);
    setPopupBtnText(t('socialWall.reportedPost.delete'));
    setPopupContent(<RemarkContent postData={getRemarkData} />);
    setPopupType('reported-delete');
    setPopupTitle(
      <PopupHeader
        title={t('socialWall.reportedPost.deletePost')}
        content={t('socialWall.reportedPost.deletePostDesc')}
      />
    );
    setDisablePopupBtn(false);
  };
  const popupAction = () => {
    if (popupType === 'suspend') {
      if (remarkData?.length > 100) {
        return false;
      }
      updateReportedPost(id, 'suspend', remarkData).then((result) => {
        setShowPopup(false);
        sessionStorage.setItem(
          'snackbar',
          JSON.stringify({ isShow: true, type: popupType })
        );
        navigate(0);
      });
    } else if (popupType === 'reported-delete') {
      if (remarkData?.length > 100) {
        return false;
      }

      deleteReportedPost(id, remarkData).then((result) => {
        setShowPopup(false);
        sessionStorage.setItem(
          'snackbar',
          JSON.stringify({ isShow: true, type: popupType })
        );
        navigate(0);
      });
    } else if (popupType === 'scheduled-delete') {
      // delete api
      deletePost(id)
        .then((result) => {
          setShowPopup(false);
          sessionStorage.setItem(
            'snackbar',
            JSON.stringify({ isShow: true, type: popupType })
          );
          if (window.location.pathname.includes('/post-detail')) {
            navigate(`/social-wall`);
          } else {
            navigate(0);
          }
        })
        .catch((err) => {
          console.error('deletePost', err);
        });
    }

    // setShowPopup(false);
    // localStorage.setItem(
    //   "snackbar",
    //   JSON.stringify({ isShow: true, type: popupType })
    // );
    // window.location.reload();
  };

  const timeFormat = (time: any) => {
    let formatted = moment(time * 1000).fromNow();
    if (formatted === 'a few seconds ago') {
      formatted = t('socialWall.justNow');
    }
    if (moment().diff(moment(time * 1000), 'days') > 7) {
      formatted = convertDate(time, true);
    }
    if (moment().diff(moment(time * 1000), 'years') >= 1) {
      formatted = convertDate(time, false, true);
    }
    // console.log("timeFormat", formatted);
    return formatted;
  };
  const postHeader = (
    <>
      {/* <Box
        className={styles.profileIcon}
        sx={{
          backgroundImage: `url('${
            props.userProfilePictureUrl ? props.userProfilePictureUrl : userIcon
          }')`,
        }}
      >
        {isPersonalAdmin && (
          <img
            className={styles.starIcon}
            src="/assets/images/admin_star_icon.svg"
          />
        )}
      </Box> */}
      <Avatar
        alt={props.createByName?.userName}
        src={
          isPostAnonymously
            ? '/assets/images/profileIcon_admin.png'
            : props.userProfilePictureUrl
              ? `${props.userProfilePictureUrl}?cache=true`
              : ''
        }
        onClick={() => {
          if (!isPostAnonymously) {
            navigate(`/discover-people/profile/${props.createBy}`);
          }
        }}
      />
      <Box className={styles.postInfoContainer}>
        <Typography
          className={styles.userName}
          variant={`${size === 'small' ? 'body2' : 'body1'}`}
        >
          {isPostAnonymously
            ? t('general.starbucksAdmin')
            : (props.createByName?.userName ?? props.createByName?.name)}
        </Typography>
        <Box
          className={styles.subInfo}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '4px',
          }}
        >
          {isPersonalAdmin && (
            <Typography className={styles.adminTag}>Admin</Typography>
          )}
          <Typography className={styles.timeStamp} variant="body2">
            {isSchedulePost && scheduleStartTime
              ? timeFormat(scheduleStartTime)
              : // : lastUpdateTime
                // ? timeFormat(lastUpdateTime * 1000)
                timeFormat(createTime)}
          </Typography>
        </Box>
      </Box>
    </>
  );

  const postContent = (
    <Typography
      className={styles.caption}
      component="div"
      variant={`${size === 'small' ? 'body2' : 'body1'}`}
    >
      {content && <Box>{handleTag(convertUrlsToLinks(content))}</Box>}

      {link &&
        (actionBtnType === 'admin' ? (
          <Box className={styles.link}>{link}</Box>
        ) : (
          <Link href={link} target="_blank">
            {link}
          </Link>
        ))}
    </Typography>
  );

  return (
    <>
      <Card
        className={`${styles.cardWrapper} ${styles['cardSize--' + size]} ${
          styles[actionBtnType]
        }`}
      >
        {(actionBtnType === 'admin' || actionBtnType === 'adminRead') &&
          status !== 'scheduled' && (
            <Box
              className={styles.postAdminInfoContainer}
              sx={{
                marginBottom: `${status === 'delete' ? '0px' : '-16px'}`,
              }}
            >
              <Box className={styles.postAdminInfoRow}>
                <Typography
                  variant={'body1'}
                  className={styles.label}
                  color={theme.vars.palette.black.opacity38}
                >
                  {t('socialWall.reportedPost.postId')}
                </Typography>

                <Typography
                  variant={'body1'}
                  color={theme.vars.palette.black.opacity87}
                >
                  {id}
                </Typography>
              </Box>
              <Box className={styles.postAdminInfoRow}>
                <Typography
                  variant={'body1'}
                  className={styles.label}
                  color={theme.vars.palette.black.opacity38}
                >
                  {t('socialWall.reportedPost.reportBy')}
                </Typography>
                <Box
                  className={styles.reportedBy}
                  onClick={() => {
                    // handleGetReportDetail && handleGetReportDetail(id);
                    getReportDetail(id);
                  }}
                >
                  {reportBy && (
                    <Typography
                      variant={'body1'}
                      color={theme.vars.palette.black.opacity87}
                    >
                      {reportBy.length === 1 && reportBy[0].userName}
                      {reportBy.length === 2 &&
                        `${reportBy[0].userName} ${t(
                          'socialWall.reportedPost.reportByAnd'
                        )} ${reportBy[1].userName}`}
                      {reportBy.length > 2 &&
                        `${reportBy[0].userName}, ${reportBy[1].userName}  ${t(
                          'socialWall.reportedPost.reportByUnit',
                          {
                            number: reportBy.length - 2,
                          }
                        )}`}
                    </Typography>
                  )}
                  <img src="/assets/images/chevron_right_black.svg" alt="" />
                </Box>
              </Box>
            </Box>
          )}
        {props.partnerNetworkGroupName && props.partnerNetworkGroupId && (
          <Link
            className={styles.groupName}
            // href={`/partner-network/detail/${props.partnerNetworkGroupId}`}
            onClick={() => {
              navigate(
                `/partner-network/detail/${props.partnerNetworkGroupId}`
              );
            }}
          >
            <img src="/assets/images/group_icon.svg" alt="group" />
            {getApiDataByLang(props.partnerNetworkGroupName)}
          </Link>
        )}
        <CardContent
          className={styles.cardContent}
          onClick={() => {
            if (
              actionBtnType === 'admin' &&
              !initLocation.includes('/post-detail')
            ) {
              navigate(`/social-wall/reported-post/post-detail/${id}`);
            } else if (size === 'small') {
              if (handleActionBtnClick) {
                handleActionBtnClick();
              } else {
                navigate(`/social-wall/post-detail/${id}`);
              }
            }
          }}
        >
          <Box
            className={styles.cardHeader}
            {...(isHK && { style: { paddingRight: '16px' } })}
          >
            {/* {actionBtnType !== "admin" && postHeader} */}
            {postHeader}
            {actionBtnType !== 'admin' && actionBtnType !== 'scheduled' && (
              <Box className={styles.endIconContainer}>
                {isPin && (
                  <img
                    className={styles.pin}
                    src="/assets/images/pin.svg"
                    alt=""
                  />
                )}
                {size === 'large' && (
                  <img
                    src={`/assets/images/${isHK ? 'moreAction_iconHK' : 'moreAction_icon'}.svg`}
                    alt=""
                    onClick={openMoreActionPopup}
                  />
                )}
              </Box>
            )}
          </Box>
          {status === 'delete' && (
            <Box sx={{ marginBottom: '10px', marginRight: '16px' }}>
              <NoticeBox
                type="info"
                text={t('socialWall.reportedPost.deletedByPartner')}
              />
            </Box>
          )}
          {postContent}
          {pollInfo && pollInfo.votings?.length > 0 && (
            <Box className={styles.pollContainer}>
              {pollInfo.title && (
                <Typography
                  className={styles.title}
                  variant={`${size === 'small' ? 'subtitle2' : 'subtitle1'}`}
                  color="text.secondary"
                >
                  {pollInfo.title &&
                    handleTag(convertUrlsToLinks(pollInfo.title))}
                </Typography>
              )}
              <Box className={styles.pollTypeContainer}>
                <img
                  src={
                    pollInfo.isVoteAnonymously
                      ? '/assets/images/anonymousPoll_icon.svg'
                      : '/assets/images/identifiedPoll_icon.svg'
                  }
                ></img>
                <Typography variant="body1" color="text.secondary">
                  {pollInfo.isVoteAnonymously
                    ? t('socialWall.poll.anonymousPoll')
                    : t('socialWall.poll.identifiedPoll')}
                </Typography>
              </Box>
              {pollInfo && pollInfo.votings && (
                <PollButton
                  postID={id}
                  data={pollInfo.votings}
                  isPictureChoice={isPictureChoice}
                  stat={votingStat}
                  voted={voteOption ? voteOption.toString() : ''}
                  disabled={
                    actionBtnType === 'admin' ||
                    actionBtnType === 'adminRead' ||
                    actionBtnType === 'scheduled' ||
                    moment().isAfter(moment.unix(pollInfo.pollEndTime).format())
                  }
                ></PollButton>
              )}
              <Box className={styles.pollDetailsContainer}>
                <Typography variant="body2">
                  {t('homepage.expiresOn', {
                    date: convertDate(pollInfo.pollEndTime, false, true),
                    time: convertTime(pollInfo.pollEndTime),
                  })}
                </Typography>
                {((userState?.permission?.SocialWallAdmin &&
                  isPostAnonymously) ||
                  userState.partnerId === createBy) && (
                  <Button
                    color="secondary"
                    onClick={() => {
                      handleGoToPage();
                    }}
                  >
                    {t('socialWall.poll.view')}
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </CardContent>

        {attachments && attachments.length > 0 && attachments[0] !== null && (
          <Box className={styles.mediaContainer}>
            <>
              {attachments[0].type === 'VIDEO' ||
              attachments[0].type === 'IMAGE' ? (
                <Box
                  sx={{
                    position: 'relative',
                  }}
                  onClick={() => {
                    setShowMediaPopup(true);
                    setShowMediaPopupIndex(0);
                  }}
                >
                  <CardMedia
                    className={
                      attachments?.length === 1 &&
                      size === 'large' &&
                      attachments[0].type === 'IMAGE'
                        ? styles.fullwidthPhoto
                        : styles.keyMedia
                    }
                    component={
                      attachments[0].type === 'VIDEO' ? 'video' : 'img'
                    }
                    loading="lazy"
                    src={`${attachments[0].fileUrl}?cache=true${
                      attachments[0].type === 'VIDEO' ? '#t=0.001' : ''
                    }`}
                  />
                  {attachments[0].type === 'VIDEO' ? (
                    <Box className={styles.videoThumbnail}>
                      <img src="/assets/images/video_playBtn.svg" />
                    </Box>
                  ) : (
                    ''
                  )}
                </Box>
              ) : (
                <Link href={attachments[0].fileUrl}>
                  <Box className={styles.fileContainer}>
                    <CardMedia
                      className={styles.fileIcon}
                      component="img"
                      loading="lazy"
                      src={
                        attachments[0].type
                          ? `/assets/images/files/file_${attachments[0].type.toLowerCase()}.svg?cache=true`
                          : ''
                      }
                    />
                  </Box>{' '}
                </Link>
              )}

              {attachments?.length !== (0 || 1) && (
                <Box className={styles.subMediaContainer}>
                  {attachments?.map((item: any, index: number) => (
                    <React.Fragment key={index}>
                      {index > 0 && index < 3 && (
                        <Box className={styles.subMediaWrapper}>
                          {item.type === 'VIDEO' || item.type === 'IMAGE' ? (
                            <Box
                              onClick={() => {
                                setShowMediaPopup(true);
                                setShowMediaPopupIndex(index);
                              }}
                            >
                              <CardMedia
                                className={styles.photoContainer}
                                component={
                                  item.type === 'VIDEO' ? 'video' : 'img'
                                }
                                loading="lazy"
                                src={`${item.fileUrl}?cache=true${
                                  item.type === 'VIDEO' ? '#t=0.001' : ''
                                }`}
                              />
                            </Box>
                          ) : (
                            <Link href={item.fileUrl}>
                              <Box className={styles.fileContainer}>
                                <CardMedia
                                  className={styles.fileIcon}
                                  component="img"
                                  loading="lazy"
                                  src={
                                    item.type
                                      ? `/assets/images/files/file_${item.type.toLowerCase()}.svg?cache=true`
                                      : ''
                                  }
                                />
                              </Box>
                            </Link>
                          )}

                          {index === 2 && attachments?.length > 3 ? (
                            <Box className={styles.moreMedia}>
                              <Typography color="white">
                                + {attachments?.length - 3}
                              </Typography>
                            </Box>
                          ) : item.type === 'VIDEO' ? (
                            <Box className={styles.videoThumbnail}>
                              <img
                                src="/assets/images/video_playBtn.svg"
                                style={{ width: '30px' }}
                              />
                            </Box>
                          ) : (
                            ''
                          )}
                        </Box>
                      )}
                    </React.Fragment>
                  ))}
                </Box>
              )}
            </>
          </Box>
        )}
        {event && <EventResultCard data={event} />}
        {size === 'large' && actionBtnType !== 'scheduled' && (
          <PostActionButton
            postId={id}
            type={type}
            likesNo={likesNo}
            commentsNo={commentsNo}
            isLikedFromApi={isLike}
            isLiked={isLiked}
            hideActionBtn={
              actionBtnType === 'admin' || actionBtnType === 'adminRead'
            }
            setLike={setIsLiked}
            likeBtnCallback={likeBtnCallback}
            disableLike={disableLike ?? false}
            disableComment={disableComment ?? false}
            {...(handleActionBtnClick && {
              customAction: handleActionBtnClick,
            })}
          />
        )}

        {size === 'large' && actionBtnType === 'scheduled' && (
          <Box className={styles.postActionBtnContainer}>
            <Button
              className={styles.infoItem}
              disabled={createBy !== userState.partnerId ? true : false}
              onClick={handleDeletePost}
            >
              <img
                className={styles.icon}
                src="/assets/images/scheduledpostActionIcon_delete.svg"
                alt=""
              />

              <Typography variant="body1">
                {t('socialWall.deleteLabel')}
              </Typography>
            </Button>

            <Button
              className={styles.infoItem}
              disabled={createBy !== userState.partnerId ? true : false}
              onClick={() => {
                navigate(`/social-wall/edit/${id}`);
              }}
            >
              <img
                className={styles.icon}
                src="/assets/images/scheduledpostActionIcon_edit.svg"
                alt=""
              />

              <Typography variant="body1">{t('socialWall.edit')}</Typography>
            </Button>
          </Box>
        )}
        {size === 'large' &&
          actionBtnType === 'admin' &&
          reportStatus !== 'resolve' &&
          reportStatus !== 'keep' && (
            <Box
              className={`${styles.adminBtnContainer} ${
                fixedBtn === true ? styles.fixedBottom : ''
              }`}
            >
              {status === 'delete' ? (
                <RoundButton
                  className={'small'}
                  data-button="resolveButton"
                  onClick={handleResolve}
                >
                  {t('socialWall.reportedPost.resolve')}
                </RoundButton>
              ) : reportStatus !== 'suspend' ? (
                <>
                  <RoundButton
                    className={'reversetype small'}
                    data-button="keepButton"
                    onClick={handleKeep}
                  >
                    {t('socialWall.reportedPost.keep')}
                  </RoundButton>

                  <RoundButton
                    className={'small'}
                    data-button="suspendButton"
                    onClick={handleSuspend}
                  >
                    {t('socialWall.reportedPost.suspend')}
                  </RoundButton>

                  {actionBtnType !== 'admin' && (
                    <RoundButton
                      className={'small'}
                      data-button="deleteButton"
                      onClick={handleReportedDelete}
                    >
                      {t('socialWall.reportedPost.delete')}
                    </RoundButton>
                  )}
                </>
              ) : (
                <RoundButton
                  className={'small'}
                  data-button="restoreButton"
                  onClick={handleRestore}
                >
                  {t('socialWall.reportedPost.restore')}
                </RoundButton>
              )}
            </Box>
          )}
      </Card>
      {showPopup && (
        <Popup
          isOpen={showPopup}
          setIsOpen={(close: boolean) => setShowPopup(close)}
          title={popupTitle}
          content={popupContent}
          disableActions={disablePopupBtn}
          confirmBtnText={popupBtnText}
          onClickConfirm={popupAction}
          buttonColor={popupType === 'scheduled-delete' ? '#C82014' : ''}
          onClickCancel={() => setRemarkData('')}
        />
      )}
      {showMediaPopup && attachments && (
        <MediaPopup
          isOpen={showMediaPopup}
          setIsOpen={(close: boolean) => setShowMediaPopup(close)}
          content={
            <Box
              className={`${styles.mediaPopup} ${
                showMediaText ? styles.showText : ''
              }`}
              onClick={() => setShowMediaText((isOpen: boolean) => !isOpen)}
            >
              <Box className={styles.popupContentWrapper}>
                <Box className={styles.cardHeader}>{postHeader}</Box>
                {postContent}
              </Box>
              <PostActionButton
                postId={id}
                type={type}
                likesNo={likesNo}
                commentsNo={commentsNo}
                isLikedFromApi={isLike}
                isLiked={isLiked}
                hideDetails={true}
                // commentAction={() => {
                //   setShowMediaPopup(false);
                // }}
                setLike={setIsLiked}
                disableLike={disableLike ?? false}
                disableComment={disableComment ?? false}
                {...(handleActionBtnClick
                  ? {
                      customAction: handleActionBtnClick,
                    }
                  : { commentAction: () => setShowMediaPopup(false) })}
              />
            </Box>
          }
          media={attachments}
          initialSlide={showMediaPopupIndex}
        />
      )}

      {getReportDetailData && (
        <Dialog
          id="reportedPostDetails"
          open={isReportDetailOpen}
          onClose={handleClosePopup}
          fullScreen
          TransitionComponent={Transition}
          PaperProps={{
            sx: {
              overflow: 'hidden',
            },
          }}
        >
          <Grid item xs className={styles.reportDetailPopup}>
            <Stack flexWrap="nowrap" className="reportDetailContainer">
              <Grid container alignItems="center">
                <Grid item xs="auto">
                  <CloseButton onClick={handleClosePopup} />
                </Grid>
                <Grid item xs>
                  <Typography variant="h4" sx={{ fontSize: '16px' }}>
                    {t('socialWall.reportedPost.reportBy')} (
                    {getReportDetailData.length})
                  </Typography>
                </Grid>
              </Grid>
              <DialogContent className="reportListContainer">
                <List className="reportList" disablePadding>
                  {getReportDetailData.map((item, index) => {
                    // console.log(item);
                    return <ReportDetailCard data={item} key={index} />;
                  })}
                </List>
              </DialogContent>
            </Stack>
          </Grid>
        </Dialog>
      )}
    </>
  );
}
