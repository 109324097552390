import { useState } from 'react';

import { Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ListItem from '../../components/content-hub/list-item';
import Header from '../../components/header/header';
import InfiniteScrollContainer from '../../components/infinite-scroll-container/InfiniteScrollContainer';

import { getGroupList, hideGroup } from '../../services/partner-network';
import { getApiDataByLang } from '../../utility';
import GroupActionPopup from './components/groupActionPopup';

type Props = {};

const PartnerNetworkManagement = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [groupList, setGroupList] = useState<Array<any>>([]);
  const [isHideGroupOpen, setHideGroupOpen] = useState<boolean>(false);
  const [popupData, setPopupData] = useState<any>(null);

  const convertData = (response: any, isNext: boolean) => {
    const data = response.result;
    if (data) {
      const temp = data.map((item: any) => {
        return {
          id: item._id,
          title: getApiDataByLang(item.groupName),
          image: item.coverImageUrl,
          isActive: !item.isHidden,
        };
      });
      if (!isNext) {
        setGroupList(temp);
      } else {
        setGroupList((prev: any) => [...prev, ...temp]);
      }
    }
  };

  const handlePopupAction = async () => {
    try {
      const response = await hideGroup({
        groupId: popupData.id,
        isHidden: popupData.isActive,
      });
      if (response.status === 200) {
        // console.log("response", response);
        window.location.reload();
      }
    } catch (error) {
      throw error;
    }
  };
  return (
    <>
      <Header
        title={t('partnerNetwork.groupManagement')}
        enableCloseButton
        closeButtonFunction={() => {
          navigate(-1);
        }}
      >
        <Grid container spacing={2} direction="row" justifyContent="flex-end">
          <Grid item xs="auto">
            <IconButton
              aria-label="Plus"
              sx={{ padding: '0px' }}
              onClick={() => navigate('/partner-network/create-group')}
            >
              <img src="/assets/images/toolbar_plus_black.svg" alt="" />
            </IconButton>
          </Grid>
        </Grid>
      </Header>
      <div style={{ padding: '0 16px' }}>
        <InfiniteScrollContainer
          fetchData={getGroupList}
          returnData={convertData}
          list={groupList}
          apiParams={{ isFullList: true }}
        >
          {groupList.length > 0 &&
            groupList.map((item, index) => {
              return (
                <ListItem
                  {...item}
                  isGroup
                  isEdit
                  key={`joined-${index}`}
                  onEyeBtnClick={(data: any) => {
                    setPopupData(data);
                    setHideGroupOpen(true);
                  }}
                  onEditClick={(id: any) => {
                    navigate(`/partner-network/edit-group/${id}`);
                  }}
                />
              );
            })}
        </InfiniteScrollContainer>
      </div>
      {popupData && (
        <GroupActionPopup
          isOpen={isHideGroupOpen}
          setIsOpen={setHideGroupOpen}
          title={
            popupData.isActive
              ? t('partnerNetwork.hideGroup')
              : t('partnerNetwork.unHide')
          }
          sub={
            popupData.isActive
              ? t('partnerNetwork.groupWillHide')
              : t('partnerNetwork.groupWillShow')
          }
          confirmBtnText={
            popupData.isActive
              ? t('partnerNetwork.hide')
              : t('partnerNetwork.unHide')
          }
          onClickConfirm={handlePopupAction}
        />
      )}
    </>
  );
};

export default PartnerNetworkManagement;
