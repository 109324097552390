import { Box, Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import theme from '../../theme';
import { isHKStyle } from '../../utility';
import styles from './shortcut-menu.module.scss';

interface IBreadcrumbProps {
  data: Array<any>;
  showMore?: boolean;
}

const ShortcutMenu = (props: IBreadcrumbProps) => {
  const { t } = useTranslation();
  const { data, showMore = false } = props;
  const navigate = useNavigate();

  return (
    <>
      <Box className={styles.shortcutsWrapper}>
        <Grid
          container
          className={`${styles.shortcutItemContainer} ${isHKStyle() ? styles.hkStyle : ''}`}
          {...(isHKStyle() && { spacing: '8px' })}
        >
          {data.map((item, index) => {
            if (!item.disabled) {
              return (
                <Grid item xs={3} key={`shortcutItem-${index}`}>
                  <Link
                    className={`${styles.shortcutItem}`}
                    href={item.url}
                    underline="none"
                    color={theme.vars.palette.black.opacity87}
                    {...(item.isOpenNewTab && {
                      target: '_blank',
                      rel: 'noopener noreferrer',
                    })}
                  >
                    <img src={item.image} alt="" />
                    <Typography variant="body2">{t(item.text)}</Typography>
                  </Link>
                </Grid>
              );
            }
          })}
          {showMore && (
            <Grid item xs={3} key={`shortcutItem-more`}>
              <Link
                className={styles.shortcutItem}
                // href={item.url}
                underline="none"
                color={theme.vars.palette.black.opacity87}
                onClick={() => navigate('/menu')}
              >
                <img src="/assets/images/shortcutMenu_more.svg" alt="" />
                <Typography variant="body2">{t('homepage.more')}</Typography>
              </Link>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default ShortcutMenu;
