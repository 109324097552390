import { FC, useEffect, useState } from 'react';

import {
  Alert,
  Box,
  Grid,
  IconButton,
  Slide,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useTranslation } from 'react-i18next';
import { UserState } from "../../reducers/user-slice";
import { verifyQrCode } from '../../services/event';
import styles from './qr-code-scanner.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface QrCodeScannerProps {
  open: boolean;
  onClose: () => void;
}

const QrCodeScanner: FC<QrCodeScannerProps> = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [scanResult, setScanResult] = useState({
    open: false,
    isSuccess: false,
    message: '',
  });

  const handleScan = async (result: string) => {
    if (scanResult.open) return false;

    setIsLoading(true);
    try {
      if (result && (result.startsWith('http') || result.startsWith('https'))) {
        window.location.href = result;
      } else {
        if (
          Boolean(userState.permission?.EventAdmin) ||
          Boolean(userState.permission?.SocialWallAdmin)
        ) {
          const response = await verifyQrCode({ qrCode: result });

          if (response.status === 200) {
            setScanResult({
              open: true,
              isSuccess: true,
              message: t('event.scanReturnStatus.success'),
            });
          } else {
            setScanResult({
              open: true,
              isSuccess: false,
              message: t('event.scanReturnStatus.fail'),
            });
          }
          setIsLoading(false);
        } else {
          setScanResult({
            open: true,
            isSuccess: false,
            message: t('event.scanReturnStatus.noPermission'),
          });
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.response.data.status === 4018) {
        setScanResult({
          open: true,
          isSuccess: false,
          message: t('event.scanReturnStatus.checked'),
        });
      } else {
        setScanResult({
          open: true,
          isSuccess: false,
          message: t('event.scanReturnStatus.fail'),
        });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!props.open && scanResult.open) {
      setScanResult((prev) => ({
        ...prev,
        open: false,
        message: '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <Slide direction="left" in={props.open} mountOnEnter unmountOnExit>
      <Box id="QrCodeScanner" className={styles.root}>
        <Grid container alignItems="center" className={styles.header}>
          <Grid item xs="auto">
            <IconButton
              className={styles.backButton}
              disableRipple
              onClick={props.onClose}
            >
              <img src="/assets/images/arrow_left_white.svg" alt="" />
            </IconButton>
          </Grid>
          <Grid item xs="auto">
            <Typography variant="h4">{t('event.scan')}</Typography>
          </Grid>
        </Grid>
        <Typography
          className={styles.content}
          variant="subtitle1"
          align="center"
        >
          {t('event.scanContent')}
        </Typography>
        <Stack
          id="scanner"
          alignItems="center"
          justifyContent="center"
          className={styles.container}
        >
          <QrScanner
            scanDelay={1000}
            onDecode={(result) => handleScan(result)}
            onError={(error) => console.error(error?.message)}
          />
        </Stack>
        <Snackbar
          open={scanResult.open}
          autoHideDuration={6000}
          onClose={() => setScanResult((prev) => ({ ...prev, open: false }))}
        >
          <Alert severity={scanResult.isSuccess ? 'success' : 'error'}>
            {scanResult.message}
          </Alert>
        </Snackbar>
      </Box>
    </Slide>
  );
};

export default QrCodeScanner;
