import React, { useEffect } from 'react';

import { Box, Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Header from '../../components/header/header';

// import { UserState } from "../../reducers/user-slice";
import { getQuickAccessList } from '../../services/homepage';
import { getApiDataByLang, isHKStyle } from '../../utility';
import styles from './menu.module.scss';

function Menu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [menuData, setMenuData] = React.useState<any>([]);

  useEffect(() => {
    const getShortcutMenuList = async () => {
      try {
        const response: any = await getQuickAccessList();
        // console.log("getShortcutMenuList", response);
        if (response.data && response.data.quickAccessData.length > 0) {
          response.data.quickAccessData.forEach((item: any) => {
            const temp = {
              id: item._id,
              title: getApiDataByLang(item.categoryName),
              content: item.content
                .map((contentItem: any) => {
                  return (
                    contentItem.isDisplayInApp && {
                      image: contentItem.displayImage,
                      url: contentItem.linkTo,
                      text: getApiDataByLang(contentItem.quickAccessName),
                      isOpenNewTab: contentItem.isOpenNewTab,
                    }
                  );
                })
                .filter(Boolean),
            };
            // console.log("getShortcutMenuList", temp);
            setMenuData((prev: any) => [...prev, temp]);
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    getShortcutMenuList();
  }, []);
  return (
    <>
      <Header
        title={t('homepage.more')}
        enableCloseButton
        closeButtonNavigation="/home"
      />
      {menuData && menuData.length > 0 && (
        <Grid item xs className={styles.pageWrapper}>
          {menuData.map((section: any) => {
            return (
              section.content.length > 0 && (
                <Box key={section.id} className={styles.sectionContainer}>
                  <Typography
                    className={styles.sectionTitle}
                    {...(isHKStyle() && { style: { fontSize: '18px' } })}
                  >
                    {t(section.title)}
                  </Typography>
                  <Box className={styles.itemContainer}>
                    {section.content.map((item: any, index: any) => {
                      return (
                        <Link
                          key={index}
                          className={styles.itemWrapper}
                          href={item.url}
                          underline="none"
                          {...(item.isOpenNewTab && {
                            target: '_blank',
                            rel: 'noopener noreferrer',
                          })}
                        >
                          <img src={item.image} alt="" />
                          <Typography variant="body2">
                            {t(item.text)}
                          </Typography>
                        </Link>
                      );
                    })}
                  </Box>
                </Box>
              )
            );
          })}
        </Grid>
      )}
    </>
  );
}

export default Menu;
