import React, { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';
// import { fa } from "@faker-js/faker";
import {
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Snackbar,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { BottomNavMenu, NoResults } from '../../components';
import FilesMediaPopup, {
  folderDetailType,
  PopupProps,
} from '../../components/files-media-popup';
import Header from '../../components/header/header';
import SearchField from '../../components/search-field';

import { UserState } from '../../reducers/user-slice';
import {
  createFiles,
  getFilesDetail,
  getFolder,
  getFolderDetail,
  getRootFolderDetail,
  getTeamsFileList,
  teamsFilesSearch,
} from '../../services/files';
import { RootState } from '../../store/store';
import { getContentTypeFromExtension, getMSTokenSilent } from '../../utility';
import { formatBytes } from '../../utility/formatBytes';
import styles from './files.module.scss';
import HandlePopup from './handlePopup';
import { File, getFileTypeFromExtension, makeData } from './makeData';

type Props = {};

type ViewProps = {
  data: File[];
  onClickItem: (file: File) => void;
  onClickMore: (detail: File) => void;
};
const isTeams = process.env.REACT_APP_LOCATION === 'PHL';
function fileTypeValidation(typeString: string) {
  const type = typeString.split('/')[1];
  let valid = true;
  const validTypes: string[] = [
    'msword', // doc
    'vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
    'pdf',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
    'vnd.ms-excel', // xls
    'csv',
    'vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
    'vnd.ms-powerpoint', // ppt
    'plain',
    'jpg',
    'jpeg',
    'png',
    'mp4',
    'quicktime', // mov
    'mpeg', // mp3
    'zip',
  ];
  if (!validTypes.includes(type)) {
    valid = false;
  }
  return valid;
}
const ListView = (props: ViewProps) => {
  const { data, onClickItem, onClickMore } = props;
  const { t } = useTranslation();
  return (
    data && (
      <ul className={styles.listView}>
        {data.map((file, index) => {
          const updateDate = moment(new Date(file.lastUpdateDate)).format(
            'DD MMM, YY hh:mm A'
          );
          return (
            <li key={index} className={styles.item}>
              <div className={styles.content} onClick={() => onClickItem(file)}>
                <img
                  src={`/assets/images/files/file_${file.file.type}.svg`}
                  alt={file.file.type}
                />
                <div className={styles.info}>
                  <h3>{`${file.file.name}${
                    file.file.type !== 'folder' ? `.${file.file.extension}` : ''
                  }`}</h3>
                  <p>{`${updateDate} ${t('general.modified')}`}</p>
                </div>
              </div>
              <button type="button" onClick={() => onClickMore(file)}>
                <img src="/assets/images/files/more_button.svg" alt="more" />
              </button>
            </li>
          );
        })}
      </ul>
    )
  );
};

const GridView = (props: ViewProps) => {
  const { data, onClickItem, onClickMore } = props;
  return (
    data && (
      <Grid container spacing={'15px'} className={styles.gridView}>
        {data.map((file, index) => {
          return (
            <Grid item xs={6} className={styles.item} key={index}>
              <div className={styles.img} onClick={() => onClickItem(file)}>
                {file.file.type === 'image' && !isTeams ? (
                  <div
                    className={styles.thumbnail}
                    style={{ backgroundImage: `url(${file.file.image})` }}
                  ></div>
                ) : (
                  <>
                    {file.file.type === 'video' ? (
                      <>
                        <video src={`${file.file.image}#t=0.001`} />
                        <img
                          src="/assets/images/video_playBtn.svg"
                          alt="play"
                          className={styles.playBtn}
                        />
                      </>
                    ) : (
                      <img
                        src={`/assets/images/files/file_${file.file.type}.svg`}
                        alt={file.file.type}
                      />
                    )}
                  </>
                )}
              </div>
              <div className={styles.info}>
                <h3>{`${file.file.name}${
                  file.file.type !== 'folder' ? `.${file.file.extension}` : ''
                }`}</h3>
                <button type="button" onClick={() => onClickMore(file)}>
                  <img src="/assets/images/files/more_button.svg" alt="more" />
                </button>
              </div>
            </Grid>
          );
        })}
      </Grid>
    )
  );
};

const Files = (props: Props) => {
  const { instance, accounts } = useMsal();
  const { id, driveId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState(() => makeData(0));
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [isMediaOpen, setMediaOpen] = useState(false);
  const [popupDetail, setPopupDetail] = useState<PopupProps>({
    type: '',
    content: null,
    folder: null,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get('search')
      ? decodeURI(searchParams.get('search') as string)
      : ''
  );
  const [folderId, setFolderId] = useState(id ?? '');
  const [isSearching, setIsSearching] = useState(searchValue ? true : false);

  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const isAdmin = Boolean(userState.permission?.EventAdmin);

  const displayMode = (sessionStorage.getItem('file-display-mode') ??
    'list') as 'list' | 'grid';
  const [viewMode, setViewMode] = useState<'list' | 'grid'>(
    isSearching ? 'list' : displayMode
  );
  //   console.log(data);
  function checkFiletype(filetype: string) {
    const currentType = filetype.toLowerCase();
    let result = 'other';
    const validTypes: string[] = [
      'word',
      'spreadsheet',
      'presentation',
      'pdf',
      'text',
      'image',
      'audio',
      'video',
      'compressed',
    ];
    if (validTypes.includes(currentType)) {
      result = currentType;
    }
    return result;
  }

  useEffect(() => {
    setData([]);
    const getSearchData = async () => {
      try {
        const res = await getFolder({ id: folderId, search: searchValue });
        const data = res && res.data;
        setIsLoading(false);
        Object.keys(data).forEach((key) => {
          // console.log("getFolder", key);
          data[key].map((item: any) => {
            // item.type && console.log("getFolder", checkFiletype(item.type));
            // console.log("item", item);
            setData((current: any) => [
              ...current,
              {
                file: {
                  type: key === 'folder' ? 'folder' : checkFiletype(item.type),
                  name: `${item.name}`,
                  image: item.thumbUrl ? item.thumbUrl : '',
                  extension: key === 'folder' ? 'folder' : item.fileExtension,
                },
                lastUpdateDate: item.lastUpdateTime * 1000,
                lastUpdateBy: item.lastUpdateBy,
                id: item._id,
              },
            ]);
          });
        });
      } catch (error) {
        // alert(error);
        setIsLoading(false);
      }
    };
    // if (searchValue === "") {
    //   getSearchData();
    // } else {
    //   const timer = setTimeout(() => {
    //     let params = new URLSearchParams(searchParams);
    //     params.set("search", encodeURI(searchValue));
    //     setSearchParams(params);
    //     getSearchData();
    //   }, 1000);

    //   return () => clearTimeout(timer);
    // }
    const getPHLData = async () => {
      const msToken = localStorage.getItem('MS_TOKEN');

      if (!driveId || folderId === '' || !msToken || !id)
        return navigate('/files');
      try {
        setData([]);
        const res =
          searchValue === ''
            ? await getTeamsFileList({
                driveId: driveId,
                folderId: id,
                MSToken: msToken,
              })
            : await teamsFilesSearch({
                search: searchValue,
                MSToken: msToken,
              });
        const data = res && res.data;
        let temp: File[] = data.files.map((item: any) => {
          let fileNameNoExt = item.name.split('.').slice(0, -1).join('.');
          return {
            file: {
              type: item.hasOwnProperty('folder')
                ? 'folder'
                : getFileTypeFromExtension(item.name.split('.').at(-1)),
              name: `${
                item.hasOwnProperty('folder') ? item.name : fileNameNoExt
              }`,
              image: item.thumbUrl ? item.thumbUrl : '',
              extension: item.hasOwnProperty('folder')
                ? 'folder'
                : item.name.split('.').pop(),
              webUrl: item.webUrl,
              size: item.size,
            },
            lastUpdateDate: new Date(item.lastModifiedDateTime).getTime(),
            lastUpdateBy: item.lastModifiedBy.user.displayName,
            createDate: new Date(item.createdDateTime).getTime(),
            createBy: item.createdBy.user.displayName,
            id: item.id,
          };
        });
        setData(temp);
        setIsLoading(false);
      } catch (error: any) {
        console.error(error);
        // TODO: remove status code 6000 and 4005 handler after backend fixed
        if (
          error.response.data.status === 6000 ||
          error.response.data.status === 4083 ||
          error.response.data.status === 4005
        ) {
          getMSTokenSilent(
            accounts,
            instance,
            `/files/${driveId}/${id}`,
            () => {
              getPHLData();
            }
          );
        } else {
          setIsLoading(false);
        }
      }
    };
    if (isTeams) {
      getPHLData();
    } else {
      getSearchData();
    }
  }, [searchValue, folderId, driveId, id]);

  const [folderDetail, setFolderDetail] = useState<folderDetailType>(null!);

  useEffect(() => {
    if (isTeams) {
      // setIsLoading(false);
    } else {
      folderId
        ? getFolderDetail(folderId)
            .then((response: any) => {
              // console.log("data", data);
              setFolderDetail(response.data.folder);
            })
            .catch((error) => {})
        : getRootFolderDetail()
            .then((response: any) => {
              // console.log("data", data);
              setFolderDetail(response.data.folder);
            })
            .catch((error) => {});
    }
  }, [folderId]);

  const handleItemClick = async (file: File) => {
    if (isTeams) {
      if (file.file.type === 'folder') {
        navigate(`/files/${driveId}/${file.id}`);
        setFolderId(file.id);
        setIsLoading(true);
        // window.location.reload();
      } else {
        window.open(file.file.webUrl, '_blank', 'noopener noreferrer');
      }
    } else {
      if (file.file.type === 'folder') {
        navigate(`/files/${file.id}`);
        setFolderId(file.id);
        setIsLoading(true);
        // window.location.reload();
      } else if (file.file.type === 'image' || file.file.type === 'video') {
        setPopupDetail({ type: file.file.type, content: file });
        getFilesDetail(file.id)
          .then((response: any) => {
            // console.log("data", data);
            const detail = response.data.file;
            setMediaOpen(true);
            setPopupDetail({
              type: 'details',
              content: {
                ...file,
                file: {
                  ...file.file,
                  location: `/${detail.path}`,
                  size: detail.size,
                  image: detail.fileUrl ? detail.fileUrl : '',
                  video: detail.fileUrl ? detail.fileUrl : '',
                },
                lastUpdateBy: detail.lastUpdateByName.userName,
                createBy: detail.createByName.userName,
              },
            });
          })
          .catch((error) => {});
      } else {
        try {
          const response = await getFilesDetail(file.id);
          // window.open(response.data.file.fileUrl, "_blank");
          const accessToken = localStorage.getItem('ACCESS_TOKEN');

          const getMIMEType = getContentTypeFromExtension(
            response.data.file.fileExtension
          );
          const fileResponse = await fetch(response.data.file.fileUrl, {
            method: 'get',
            credentials: 'include',
            // headers: {
            //   // Authorization: `Bearer ${accessToken}`,
            //   "Content-Type": getMIMEType,
            // },
          });
          const blob = await fileResponse.blob();
          const urlObject = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = urlObject;
          link.download = `${response.data.file.name}.${response.data.file.fileExtension}`;
          // link.download = `Test 0728_20230728104714.docx`;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          URL.revokeObjectURL(urlObject); // Clean up the temporary URL
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
  const handleMoreClick = (detail: File) => {
    setShowPopup(true);
    setPopupDetail({ type: 'more', content: detail });
  };
  const handleViewModeClick = () => {
    setViewMode(viewMode === 'list' ? 'grid' : 'list');
    sessionStorage.setItem(
      'file-display-mode',
      viewMode === 'list' ? 'grid' : 'list'
    );
  };
  const handleNewFolderClick = () => {
    setTimeout(() => {
      setShowPopup(true);
    }, 350);
    setPopupDetail({ type: 'input', content: null, folder: folderDetail });
  };

  const handleRenameClick = (detail: File) => {
    setTimeout(() => {
      setShowPopup(true);
    }, 350);
    setPopupDetail({ type: 'input', content: detail });
  };

  const handleDetailsClick = (detail: File) => {
    // console.log('handleDetailsClick', detail);
    if (detail.file.type === 'folder') {
      if (isTeams) {
        setShowPopup(true);
        setPopupDetail({
          type: 'details',
          content: {
            file: {
              ...detail.file,
              location: '/-',
              size: formatBytes(detail.file?.size ?? 0),
            },
            lastUpdateBy: detail.lastUpdateBy,
            createBy: detail.createBy,
          },
        });
      } else
        getFolderDetail(detail.id)
          .then((response: any) => {
            // console.log("data", data);
            const folder = response.data.folder;
            setTimeout(() => {
              setShowPopup(true);
            }, 350);
            setPopupDetail({
              type: 'details',
              content: {
                ...detail,
                file: {
                  ...detail.file,
                  location: folder.path,
                  size: formatBytes(folder.size),
                },
                lastUpdateBy: folder.lastUpdateByName.userName,
                createBy: folder.createByName.userName,
                lastUpdateDate: folder.lastUpdateTime,
                createDate: folder.createTime,
              },
            });
          })
          .catch((error) => {});
    } else {
      if (isTeams) {
        setShowPopup(true);
        setPopupDetail({
          type: 'details',
          content: {
            file: {
              ...detail.file,
              location: '/-',
              size: formatBytes(detail.file?.size ?? 0),
            },
            lastUpdateBy: detail.lastUpdateBy,
            createBy: detail.createBy,
          },
        });
      } else
        getFilesDetail(detail.id)
          .then((response: any) => {
            console.log('getFilesDetail', response);
            const file = response.data.file;
            setTimeout(() => {
              setShowPopup(true);
            }, 350);
            setPopupDetail({
              type: 'details',
              content: {
                ...detail,
                file: {
                  ...detail.file,
                  location: `/${file.path}`,
                  size: file.size,
                  image: file.thumbUrl ? file.thumbUrl : '',
                  video: file.fileUrl ? file.fileUrl : '',
                },
                lastUpdateBy: file.lastUpdateByName.userName,
                createBy: file.createByName.userName,
                lastUpdateDate: file.lastUpdateTime,
                createDate: file.createTime,
              },
            });
          })
          .catch((error) => {});
    }
  };

  const handleDeleteClick = (detail: File) => {
    setTimeout(() => {
      setShowPopup(true);
    }, 350);
    setPopupDetail({ type: 'delete', content: detail });
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleResetField = () => {
    setIsLoading(true);
    setSearchValue('');
  };

  const handleCloseChange = () => {
    setIsLoading(true);
    setSearchValue('');
    let params = new URLSearchParams(searchParams);
    params.delete('search');
    setSearchParams(params);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  const [isUploading, setIsUploading] = useState(false);
  const handleFileUploadChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsLoading(true);
    setIsUploading(true);
    setShowPopup(false);
    const file = event.target.files;
    // 2 Gigabytes = 2,147,483,648 Bytes
    if (file) {
      // console.log("formatBytes", file?.[0]);
      if (file?.[0].size > 2147483648) {
        sessionStorage.setItem(
          'snackbar',
          JSON.stringify({
            open: true,
            type: 'error',
            message: t('files.uploadFileError'),
          })
        );
        window.location.reload();
        // alert(`${file?.[0].name} exceeds maximum allowed size of 2 GB`);
        return;
      }
      if (!fileTypeValidation(file?.[0].type)) {
        sessionStorage.setItem(
          'snackbar',
          JSON.stringify({
            open: true,
            type: 'error',
            message: t('files.fileTypeNotAllow'),
          })
        );
        window.location.reload();
        // alert(
        //   `${file?.[0].name} is not allowed. Please upload only Word, Excel, PDF, PowerPoint, Text, Image, Audio, Video, and Zip files.`
        // );
        return;
      }
      const temp = {
        name: file?.[0].name.split('.').slice(0, -1).join('.'),
        folderId: folderDetail?._id,
        file: file?.[0],
      };
      createFiles(temp)
        .then((res) => {
          setIsUploading(false);
          sessionStorage.setItem(
            'snackbar',
            JSON.stringify({
              open: true,
              type: 'success',
              message: t('files.uploadFilesSuccess'),
            })
          );
          setShowPopup(false);
          window.location.reload();
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    }
  };

  const snackbar = sessionStorage.getItem('snackbar')
    ? JSON.parse(sessionStorage.getItem('snackbar') as string)
    : null;
  const [snackbarOpen, setSnackbarOpen] = useState(
    snackbar ? snackbar.open : false
  );
  const handleAlertClose = () => {
    setSnackbarOpen(false);
    sessionStorage.removeItem('snackbar');
  };

  // console.log("folderDetail", folderDetail);
  return (
    <>
      {isSearching ? (
        <SearchField
          id="search"
          name="search"
          placeholder={t('general.search')}
          value={searchValue}
          onChange={handleValueChange}
          onReset={handleResetField}
          onClose={handleCloseChange}
        />
      ) : (
        <Header
          title={
            isLoading
              ? t('general.loading') + '...'
              : id && folderDetail
                ? folderDetail.name
                : t('general.files')
          }
          // enableBackButton={id && folderDetail?.path !== '' ? true : false}
          enableBackButton={true}
          closeButtonFunction={() => {
            if (isTeams) {
              navigate(-1);
            } else {
              if (process.env.REACT_APP_LOCATION === 'HK') {
                window.location.href = `/menu`;
              } else if (isUploading) {
                setShowPopup(true);
                setPopupDetail({
                  type: 'upload',
                  content: `/files/${folderDetail?.parentFolderId}`,
                });
              } else {
                setIsLoading(true);
                if (
                  folderDetail?.parentFolderId === '642a9028894f688216da53c2'
                ) {
                  window.location.href = `/files`; // 642a9028894f688216da53c2 = root folder
                } else {
                  window.location.href = `/files/${folderDetail?.parentFolderId}`;
                }
                // setFolderId(folderDetail?.parentFolderId);
                // navigate(`/files/${folderDetail?.parentFolderId}`);
                // navigate('/files');
                // window.location.reload();
              }
            }
          }}
        >
          <Grid container spacing={2} direction="row" justifyContent="flex-end">
            <Grid item xs="auto">
              <IconButton
                aria-label="view mode"
                sx={{ padding: '0px' }}
                onClick={handleViewModeClick}
              >
                <img
                  src={`/assets/images/files_${
                    viewMode === 'list' ? 'grid' : 'list'
                  }.svg`}
                  alt="grid view"
                />
              </IconButton>
            </Grid>
            <Grid item xs="auto">
              <IconButton
                aria-label="Search"
                sx={{ padding: '0px' }}
                onClick={() => {
                  setIsSearching(true);
                  setViewMode('list');
                }}
              >
                <img src="/assets/images/toolbar_search_black.svg" alt="" />
              </IconButton>
            </Grid>
          </Grid>
        </Header>
      )}

      <Grid item xs className={styles.files}>
        {data.length > 0 ? (
          <>
            {viewMode === 'list' ? (
              <ListView
                data={data}
                onClickItem={(file) => handleItemClick(file)}
                onClickMore={(detail) => handleMoreClick(detail)}
              />
            ) : (
              <GridView
                data={data}
                onClickItem={(file) => handleItemClick(file)}
                onClickMore={(detail) => handleMoreClick(detail)}
              />
            )}
          </>
        ) : (
          !isLoading && (
            <div className={styles.noData}>
              <div className={styles.noDataContainer}>
                {isSearching ? (
                  <NoResults resultsType="SEARCH" />
                ) : (
                  <>
                    <img src="/assets/images/files/Empty.svg" alt="no data" />
                    <h3>{t('files.noFiles')}</h3>
                    {isAdmin && <span>{t('files.noFilesDescription')}</span>}
                  </>
                )}
              </div>
            </div>
          )
        )}

        {!isSearching && isAdmin && !isTeams && (
          <button
            type="button"
            className={styles.createButton}
            onClick={() => {
              setPopupDetail({ type: 'create', content: null });
              setShowPopup(true);
            }}
          >
            <img src="/assets/images/files/create_button.svg" alt="create" />
          </button>
        )}
      </Grid>
      {!isSearching && (
        <BottomNavMenu
          isUploading={isUploading}
          afterUpload={(url) => {
            setShowPopup(true);
            setPopupDetail({ type: 'upload', content: url });
          }}
        />
      )}
      {snackbar && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          sx={{ bottom: isSearching ? '20px' : '86px' }}
        >
          <div className={`${styles.snackbar} ${snackbar.type}`}>
            <img
              src={`/assets/images/files/alert_${snackbar.type}.svg`}
              alt={snackbar.type}
            />
            <span>{snackbar.message}</span>
          </div>
        </Snackbar>
      )}

      {isMediaOpen && (
        <FilesMediaPopup
          open={isMediaOpen}
          file={popupDetail}
          onClose={(close?: boolean) => setMediaOpen(close ?? false)}
          onClickOptions={(detail) => handleMoreClick(detail)}
        />
      )}
      <HandlePopup
        isOpen={showPopup}
        setIsOpen={setShowPopup}
        detail={popupDetail}
        onClickRename={(detail) => handleRenameClick(detail)}
        onClickNewFolder={handleNewFolderClick}
        onClickDetails={(detail) => handleDetailsClick(detail)}
        onClickDelete={(detail) => handleDeleteClick(detail)}
        onChangeFileUpload={(event) => handleFileUploadChange(event)}
        onChanged={(value) => {
          setIsLoading(true);
          console.log('folderId', value, folderId);
          if (value === folderId) {
            window.location.reload();
          } else {
            setFolderId(value);
          }
        }}
        isAdmin={isAdmin}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Files;
